import { useEffect, useState } from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import client from "../../../../api/api";
import { useAppDispatch } from "../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../hooks/translate";
import { setFilteredByGroupId } from "../../../../slices/devices-slice";
import DeviceGroupSelect from "./components/device-group-select";
import DeviceNameInput from "./components/device-name-input";
import DeviceTypeSelect from "./components/device-type-select";
import SelectNewDeviceKind from "./components/select-new-device-kind";
import UpgradeMessage from "./components/upgrade-message";

interface Props {
  device?: Device;
  onClose: () => void;
}
export default function AddEditDeviceDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const dispatch = useAppDispatch();
  const [deviceMode, setDeviceMode] = useState<string>();
  const [canAddDiyDevice, setCanAddDiyDevice] = useState(false);
  const [deviceType, setDeviceType] = useState<string>(
    props.device?.type ?? "switch",
  );
  const [name, setName] = useState<string>(props.device?.name ?? "");
  const [groupId, setGroupId] = useState<string | undefined>(
    props.device?.groupId ?? undefined,
  );

  const checkDiyDevicesAddingPermission = async () => {
    const response = await client.apiV4DevicesAddDiyDevicesAllowedGet();
    if (response.status == 200) {
      setCanAddDiyDevice(response.data.allowed ?? false);
    }
  };

  useEffect(() => {
    checkDiyDevicesAddingPermission();
  }, []);

  const handleSavePressed = async () => {
    if (props.device?._id) {
      const response = await client.apiV4DevicesDeviceIdPut(props.device._id, {
        name: name,
        type: deviceType,
        groupId: groupId,
        source: "web",
      });
      if (response.status == 200) {
        dispatch(setFilteredByGroupId(groupId));
        props.onClose();
      } else {
        props.onClose();
      }
    } else {
      const response = await client.apiV4DevicesPost({
        name: name,
        type: deviceType,
        groupId: groupId,
        source: "web",
      });
      if (response.status == 200) {
        dispatch(setFilteredByGroupId(groupId));
        props.onClose();
      } else {
        props.onClose();
      }
    }
  };

  if (!props.device && !deviceMode) {
    return <SelectNewDeviceKind onSelect={setDeviceMode} />;
  } else if (!props.device && deviceMode == "regular") {
    return <div>Add device by device id</div>;
  } else if (!props.device && deviceMode == "diy" && !canAddDiyDevice) {
    return <UpgradeMessage />;
  }

  return (
    <Box>
      <DialogTitle variant="h2">
        {props.device ? strings.editDevice : strings.addnewdevice}
      </DialogTitle>
      <DialogContent dividers>
        <DeviceNameInput value={name} onChange={setName} />
        <DeviceTypeSelect value={deviceType} onSelect={setDeviceType} />
        <DeviceGroupSelect value={groupId} onSelect={setGroupId} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="error">
          {strings.cancel}
        </Button>
        <Button onClick={handleSavePressed}>{strings.save}</Button>
      </DialogActions>
    </Box>
  );
}
