import * as React from "react";

import { DisplaySettings } from "@mui/icons-material";
import ChartIcon from "@mui/icons-material/BarChart";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MemoryIcon from "@mui/icons-material/Memory";
import MenuIcon from "@mui/icons-material/Menu";
import RoutineIcon from "@mui/icons-material/RepeatOne";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import {
  styled,
  Theme,
  CSSObject,
  ThemeProvider,
  createTheme,
} from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../hooks/redux";
import { useTranslatedStrings } from "../../hooks/translate";
import { RootState } from "../../store";
import PageBackground from "../page-background";
import AppDrawerItem from "./components/app-drawer-item";
import LogoutButton from "./components/logout-button";
import ProfileButton from "./components/profile-button";
import ThemeSwitch from "./components/theme-switch";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function MiniDrawer(props: {
  children: JSX.Element;
  title: string;
}) {
  const appTheme = useAppSelector((state: RootState) => state.app.theme);
  const user = useAppSelector((state: RootState) => state.app.user);
  const strings = useTranslatedStrings();
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const theme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#ee8003",
      },
      secondary: {
        main: "rgb(250, 250, 250)",
      },
    },
    typography: {
      h6: { fontSize: 10 },
      h5: { fontSize: 12 },
      h4: { fontSize: 18 },
      h3: { fontSize: 22 },
      h2: { fontSize: 24 },
      h1: { fontSize: 30 },
      button: {
        textTransform: "none",
      },
    },
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#ee8003",
      },
      secondary: {
        main: "rgb(73, 73, 73)",
      },
    },
    typography: {
      h6: { fontSize: 10 },
      h5: { fontSize: 12 },
      h4: { fontSize: 18 },
      h3: { fontSize: 22 },
      h2: { fontSize: 24 },
      h1: { fontSize: 30 },
      button: {
        textTransform: "none",
      },
    },
  });

  return (
    <ThemeProvider theme={appTheme == "dark" ? darkTheme : theme}>
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src="/logo.svg" height="35px" alt="logo" className="mx-2" />
            <Typography variant="h1" noWrap component="div">
              {props.title}
            </Typography>

            <ThemeSwitch />
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <ProfileButton />
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "rtl" ? (
                <ChevronRightIcon />
              ) : (
                <ChevronLeftIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />
          <List>
            <AppDrawerItem
              label={strings.devices}
              open={open}
              onClick={() => navigate("/devices")}
            >
              <MemoryIcon />
            </AppDrawerItem>
            <AppDrawerItem
              label={strings.routines}
              open={open}
              onClick={() => navigate("/routines")}
            >
              <RoutineIcon />
            </AppDrawerItem>
            <AppDrawerItem
              label={strings.charts}
              open={open}
              onClick={() => navigate("/charts")}
            >
              <ChartIcon />
            </AppDrawerItem>
            {user?.role === "admin" && (
              <AppDrawerItem
                label={strings.system}
                open={open}
                onClick={() => navigate("/system")}
              >
                <DisplaySettings />
              </AppDrawerItem>
            )}
          </List>
          <Divider />
          <LogoutButton open={open} />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          <DrawerHeader />
          <PageBackground />
          {props.children}
        </Box>
      </Box>
    </ThemeProvider>
  );
}
