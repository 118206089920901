import { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Container,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import { useAppSelector } from "../../../hooks/redux";
import { useTranslatedStrings } from "../../../hooks/translate";
import { RootState } from "../../../store";
import ProfilePicture from "./profile-picture";

export default function BasicInformation() {
  const user = useAppSelector((state: RootState) => state.app.user);
  const [name, setName] = useState(user?.name ?? "");
  const [email, setEmail] = useState(user?.email ?? "");

  const strings = useTranslatedStrings();
  const [open, setOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {strings.saved}
        </Alert>
      </Snackbar>
      <Card
        sx={{ minWidth: 275, maxWidth: 700, margin: "auto", padding: "10px" }}
      >
        <Typography variant="h3">{strings.basicInformation}</Typography>
        <CardContent>
          <Box sx={{ flexWrap: "wrap", display: "flex" }}>
            <Container sx={{ width: "181px", margin: "20px auto" }}>
              <ProfilePicture />
            </Container>
            <Container
              sx={{
                display: "flex",
                width: "auto",
                minWidth: 300,
                justifyContent: "flex-start",
                flexDirection: "column",
              }}
            >
              <TextField
                id="outlined-basic"
                label={strings.username}
                variant="outlined"
                defaultValue={user?.username}
                sx={{ marginBottom: "15px" }}
                disabled
              />

              <TextField
                id="outlined-basic"
                label={strings.name}
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ marginBottom: "15px" }}
              />
              <TextField
                id="outlined-basic"
                label={strings.email}
                variant="outlined"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Container>
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button size="small" onClick={() => setOpen(true)}>
            {strings.save}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
