import { useState } from "react";

import { Box } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import client from "../../../../api/api";
import { useTranslatedStrings } from "../../../../hooks/translate";
import {
  getGroupIconName,
  getGroupIconType,
} from "../../../../utils/group-utils";
import GroupNameInput from "./components/group-name-input";
import GroupTypeSelect from "./components/group-type-select";

interface Props {
  group?: Group;
  onClose: () => void;
}
export default function AddEditGroupDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const [groupType, setGroupType] = useState<string>(
    getGroupIconType(props.group?.iconName),
  );
  const [name, setName] = useState<string>(props.group?.name ?? "");

  const handleSavePressed = async () => {
    if (props.group && props.group._id) {
      handleEditGroup(props.group._id);
    } else {
      handleAddGroup();
    }
  };

  const handleAddGroup = async () => {
    const response = await client.apiV4GroupsPost({
      name,
      iconName: getGroupIconName(groupType),
      source: "web",
    });
    if (response.status == 200) {
      props.onClose();
    } else {
      props.onClose();
    }
  };

  const handleEditGroup = async (id: string) => {
    const response = await client.apiV4GroupsGroupIdPut(id, {
      name,
      iconName: getGroupIconName(groupType),
      source: "web",
    });
    if (response.status == 200) {
      props.onClose();
    } else {
      props.onClose();
    }
  };

  return (
    <Box>
      <DialogTitle>
        {props.group ? strings.editgroup : strings.addgroup}
      </DialogTitle>
      <DialogContent dividers>
        <GroupTypeSelect value={groupType} onSelect={setGroupType} />
        <GroupNameInput value={name} onChange={setName} />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.onClose} color="error">
          {strings.cancel}
        </Button>
        <Button onClick={handleSavePressed}>{strings.save}</Button>
      </DialogActions>
    </Box>
  );
}
