import { useState } from "react";

import { Alert, Avatar, Snackbar } from "@mui/material";

import client from "../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../hooks/redux";
import { useTranslatedStrings } from "../../../hooks/translate";
import { setUser } from "../../../slices/app-slice";
import { RootState } from "../../../store";

export default function ProfilePicture() {
  const strings = useTranslatedStrings();
  const user = useAppSelector((state: RootState) => state.app.user);
  const [open, setOpen] = useState(false);

  const dispatch = useAppDispatch();

  const onImageSelected = async (img: File) => {
    const form = new FormData();
    form.append("image", img);
    const response = await client.setProfilePicturePost(form);

    if (response.status == 200) {
      const responseUser = await client.apiV4GetMeGet();
      if (responseUser.status == 200) {
        dispatch(setUser(responseUser.data));
      }
    } else {
      setOpen(true);
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div className="">
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="error" sx={{ width: "100%" }}>
          {strings.saved}
        </Alert>
      </Snackbar>
      <div className="image-placeholder">
        <label htmlFor="file-upload" className="custom-file-upload">
          <Avatar
            alt="User Name"
            src={user?.profilePictureUrl ?? "/img/profile/default-user.png"}
            sx={{ width: 150, height: 150 }}
          />
        </label>
      </div>
      <input
        hidden
        type="file"
        id="file-upload"
        name="file-upload"
        accept="image/png, image/jpeg"
        onChange={(e) => {
          const files = e?.target?.files;
          if (files) {
            const file = files[0];
            onImageSelected(file);
          }
        }}
      />
    </div>
  );
}
