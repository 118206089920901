import { useCallback } from "react";

import { Card, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../api/api";
import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../../../../../../../../hooks/translate";

export default function GoogleAccountLinking() {
  const strings = useTranslatedStrings();
  const user = useAppSelector((state) => state.app.user);
  const integrations = user?.integrations ?? [];
  const googlePhotosIntegration = integrations.filter(
    (i) => i.name === "googlePhotos",
  )[0];
  const linked = googlePhotosIntegration?.reportToken?.refreshToken != null;

  const onLinkAccountPressed = useCallback(async () => {
    if (linked) {
      //Unlink account
    } else {
      const response = await client.googlePhotosStartGet();
      if (response.status == 200 && response.data.url) {
        window.location.href = response.data.url;
      }
    }
  }, [linked]);

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">{strings.linkedAccount}</Typography>
        <Box sx={{ flex: 2 }} />
        <Button onClick={onLinkAccountPressed}>
          {linked ? strings.unlink : strings.link}
        </Button>
      </Box>
    </Card>
  );
}
