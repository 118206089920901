import { useCallback, useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import client from "../../../../api/api";
import LoadingIndicator from "../../../../components/loading-indicator";
import { useAppDispatch } from "../../../../hooks/redux";
import {
  setFilteredByGroupId,
  setSelectedGroupId,
} from "../../../../slices/devices-slice";
import AddEditGroupDialogContent from "../add-edit-group/add-edit-group";
import ConfirmDeleteGroupDialogContent from "./components/confirm-delete/confirm-delete-group";
import GroupInfoDialogContent from "./components/group-info/group-info";

interface Props {
  groupId?: string | null;
}
export default function GroupDetailsDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { groupId } = props;
  const [group, setGroup] = useState<Group | null>(null);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [loading, setLoading] = useState(true);

  const open = groupId != null;

  const handleClose = () => {
    setLoading(true);
    setEditing(false);
    setDeleting(false);
    dispatch(setSelectedGroupId(null));
  };

  const getGroup = useCallback(async () => {
    if (!groupId) {
      setGroup(null);
      return;
    }

    const response = await client.apiV4GroupsGroupIdGet(groupId ?? "");
    if (response.status == 200) {
      setGroup(response.data);
      setLoading(false);
    }
  }, [groupId]);

  const handleDeleteConfirmed = async (groupId?: string) => {
    if (!groupId) return;
    const result = await client.apiV4GroupsGroupIdDelete(groupId);
    if (result.status == 200) {
      handleClose();
      dispatch(setFilteredByGroupId(null));
    }
  };

  useEffect(() => {
    getGroup();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId]);

  const getChild = () => {
    if (!group || loading) {
      return <LoadingIndicator />;
    } else if (editing) {
      return <AddEditGroupDialogContent onClose={handleClose} group={group} />;
    } else if (deleting) {
      return (
        <ConfirmDeleteGroupDialogContent
          group={group}
          onCancel={() => setDeleting(false)}
          onConfirm={handleDeleteConfirmed}
        />
      );
    }
    return (
      <GroupInfoDialogContent
        group={group}
        onEdit={() => setEditing(true)}
        onDelete={() => setDeleting(true)}
      />
    );
  };

  return (
    <Dialog open={open}>
      <Box sx={{ position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {getChild()}
    </Dialog>
  );
}
