import { RootState } from "@/store";

import { getStrings, translate } from "../services/local/localization";
import { useAppSelector } from "./redux";

export const useTranslate = () => {
  const language = useAppSelector((state: RootState) => state.app.language);
  return (key: string) => translate(key, language);
};

export const useTranslatedStrings = () => {
  const language = useAppSelector((state: RootState) => state.app.language);
  return getStrings(language);
};

export default useTranslate;
