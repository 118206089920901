import { useState } from "react";

import { ArrowBack, ArrowForward, CalendarMonth } from "@mui/icons-material";
import { Box, Button, IconButton } from "@mui/material";

import { isToday } from "../../../../../../../../../../../../utils/date-utils";
import DateSelectionDialog from "./components/date-selection-dialog";

interface Props {
  onDateChanged: (date: Date) => void;
  date: Date;
}

export default function ChartControls(props: Props) {
  const { date, onDateChanged } = props;
  const [selecting, setSelecting] = useState(false);

  const onBackButtonPressed = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() - 1);
    onDateChanged(newDate);
  };
  const onNextButtonPressed = () => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    onDateChanged(newDate);
  };
  const onDateSelected = (newDate: Date) => {
    onDateChanged(newDate);
    setSelecting(false);
  };

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <IconButton
        size="small"
        aria-label="back"
        onClick={onBackButtonPressed}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowBack sx={{ height: 10, width: 10 }} />
      </IconButton>
      <Button
        sx={{
          color: (theme) => theme.palette.grey[500],
          fontSize: 10,
        }}
        variant="text"
        onClick={() => setSelecting(true)}
      >
        <CalendarMonth sx={{ height: 10, width: 10 }} />
        <Box sx={{ marginLeft: "5px" }}>{date.toLocaleDateString()}</Box>
      </Button>
      <IconButton
        size="small"
        disabled={isToday(date)}
        aria-label="next"
        onClick={onNextButtonPressed}
        sx={{
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <ArrowForward sx={{ height: 10, width: 10 }} />
      </IconButton>

      <DateSelectionDialog
        open={selecting}
        date={date}
        onCancel={() => setSelecting(false)}
        onDateSelected={onDateSelected}
      />
    </Box>
  );
}
