import {
  Box,
  Button,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";

import { useTranslatedStrings } from "../../../../../../hooks/translate";

interface Props {
  device: Device;
  onCancel: () => void;
  onConfirm: () => void;
}
export default function ConfirmDeleteDeviceDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const { device, onCancel, onConfirm } = props;

  const { name } = device;

  return (
    <Box>
      <DialogTitle variant="h2">{strings.deletedeviceconfirm}</DialogTitle>
      <Box width={400} sx={{ paddingLeft: "20px" }}>
        <Typography variant="h4"> {name} </Typography>
        <DialogActions>
          <Button autoFocus onClick={onCancel}>
            cancel
          </Button>
          <Button autoFocus onClick={onConfirm} color={"error"}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}
