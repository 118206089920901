import { CloudOffOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Lottie from "lottie-react";

import animation from "../../../../../../../../../assets/animations/online.json";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";

interface Props {
  device: Device;
}
export default function DeviceInfoOnlineStatus(props: Props) {
  const strings = useTranslatedStrings();
  const itemStyle = {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };
  const isOnline = props.device.connected;
  const lastOffline = new Date(props.device.lastConnection ?? "");

  return (
    <Box sx={itemStyle}>
      <Typography variant="h5">{strings.status}:</Typography>
      {isOnline ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Lottie
            animationData={animation}
            loop
            style={{ height: 20, width: 20, marginRight: "5px" }}
          />
          <Typography variant="h5" paddingTop={"5px"}>
            {strings.online}
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <CloudOffOutlined
            style={{ height: 15, width: 15, marginRight: "5px" }}
            htmlColor="grey"
          />
          <Typography variant="h5">{strings.offlinesince}:</Typography>
          <Box width={10} />
          <Typography variant="h5">
            {lastOffline.toLocaleDateString()}{" "}
            {lastOffline.toLocaleTimeString()}
          </Typography>
        </Box>
      )}
    </Box>
  );
}
