import { FormControl, TextField } from "@mui/material";

import { useTranslatedStrings } from "../../../../../hooks/translate";

interface Props {
  value: string;
  onChange: (val: string) => void;
}
export default function DeviceNameInput(props: Props) {
  const strings = useTranslatedStrings();
  return (
    <FormControl sx={{ m: 1, minWidth: 200 }}>
      <TextField
        id="outlined-basic"
        label={strings.devicename}
        variant="outlined"
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
      />
    </FormControl>
  );
}
