import TopicDailyChart from "./base-charts/topic-daily-chart";

interface Props {
  deviceId: string;
  scale?: string;
}
export default function TemperatureChart(props: Props) {
  return (
    <TopicDailyChart
      deviceId={props.deviceId}
      topic={"temperature"}
      color="#BE0D0D"
      units={`°${props.scale ?? "C"}`}
    />
  );
}
