import { useState } from "react";

import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Box, Divider, IconButton, Link, Typography } from "@mui/material";

import useTranslate, {
  useTranslatedStrings,
} from "../../../../../../../../hooks/translate";
import DeviceSubscriptionInfo from "../device-subscription-info";
import CopyButton from "./components/copy-button";
import DeviceInfoOnlineStatus from "./components/online-status";
import DeviceInfoSignalStatus from "./components/signal-status";

interface Props {
  device: Device;
}
export default function DeviceBasicInfo(props: Props) {
  const [showMore, setShowMore] = useState(false);
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const { device } = props;

  const itemStyle = {
    paddingTop: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  return (
    <Box>
      <Typography variant="h4">{strings.deviceinfo}:</Typography>
      <DeviceInfoOnlineStatus device={device} />
      <Box sx={itemStyle}>
        <Typography variant="h5">{strings.type}:</Typography>
        <Typography variant="h5">
          {translate(device.type ?? "unknown")}
        </Typography>
      </Box>
      {showMore && (
        <Box>
          <Box sx={itemStyle}>
            <Typography variant="h5">Firmware:</Typography>
            <Typography variant="h5">{device.firmware}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography variant="h5">IP:</Typography>
            <Link href={"http://" + device.ip} variant="h5">
              {device.ip}
            </Link>
          </Box>
          <Box sx={itemStyle}>
            <Typography variant="h5">{strings.network}:</Typography>
            <Typography variant="h5">{device.network}</Typography>
          </Box>
          <Box sx={itemStyle}>
            <Typography variant="h5">{strings.signal}:</Typography>
            <DeviceInfoSignalStatus signal={device.signal ?? 0} />
          </Box>
          <Box sx={itemStyle}>
            <Typography variant="h5">ID:</Typography>
            <CopyButton content={device._id} showContent={true} />
          </Box>
          <Divider sx={{ margin: "20px 0" }} />
          {device.activationUntil && (
            <DeviceSubscriptionInfo
              activationUntil={device.activationUntil ?? ""}
            />
          )}
        </Box>
      )}
      <Box sx={{ textAlign: "center", marginTop: "30px" }}>
        <IconButton onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </IconButton>
      </Box>
    </Box>
  );
}
