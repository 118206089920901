import { Box, Button, DialogActions, DialogTitle } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../hooks/translate";

interface Props {
  group: Group;
  onCancel: () => void;
  onConfirm: () => void;
}
export default function ConfirmDeleteGroupDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const { group, onCancel, onConfirm } = props;

  return (
    <Box>
      <DialogTitle>{group ? group?.name : strings.group}</DialogTitle>
      <Box width={400}>
        <DialogActions>
          <Button autoFocus onClick={onCancel}>
            cancel
          </Button>
          <Button autoFocus onClick={onConfirm} color={"error"}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}
