import { useCallback, useEffect, useState } from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useAppSelector } from "../../../../../hooks/redux";
import useTranslate, {
  useTranslatedStrings,
} from "../../../../../hooks/translate";
import { deviceTypes } from "../../../../../utils/device-utils";

interface Props {
  value: string;
  onSelect: (type: string) => void;
}
export default function DeviceTypeSelect(props: Props) {
  const strings = useTranslatedStrings();
  const user = useAppSelector((state) => state.app.user);
  const [userDeviceTypes, setUserDeviceTypes] = useState([...deviceTypes]);
  const translate = useTranslate();
  const handleChange = (event: SelectChangeEvent) => {
    props.onSelect(event.target.value);
  };

  const addUserIntegrationsDeviceTypes = useCallback(() => {
    if (!user || !user.integrations) return;

    const { integrations } = user;

    const newDeviceTypes = [...userDeviceTypes];
    for (let i = 0; i < integrations.length; i++) {
      const integration = integrations[i];
      if (integration.name == "zigbee2mqtt" && integration.enabled) {
        newDeviceTypes.push("zigbee2mqtt");
      }
      if (integration.name == "homeAssistant" && integration.enabled) {
        newDeviceTypes.push("homeAssistant");
      }
      if (integration.name == "homebridge" && integration.enabled) {
        newDeviceTypes.push("homebridge");
      }
      if (integration.name == "googlePhotos" && integration.enabled) {
        newDeviceTypes.push("photobooth");
      }
    }
    setUserDeviceTypes(newDeviceTypes);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(addUserIntegrationsDeviceTypes, [addUserIntegrationsDeviceTypes]);

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel>{strings.type}</InputLabel>
        <Select
          value={props.value}
          label={strings.type}
          onChange={handleChange}
        >
          {userDeviceTypes.map((deviceType: string) => (
            <MenuItem key={deviceType} value={deviceType}>
              {translate(deviceType)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
