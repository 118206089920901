import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import TopicBinaryStatusChart from "./base-charts/topic-binary-status-chart";

interface Props {
  deviceId: string;
}
export default function OnlineChart(props: Props) {
  const strings = useTranslatedStrings();

  return (
    <TopicBinaryStatusChart
      deviceId={props.deviceId ?? ""}
      topic={"online"}
      color="#256C25"
      valueWhenFalse={strings.offline}
      valueWhenTrue={strings.online}
      heigth={70}
    />
  );
}
