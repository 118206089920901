import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";

import { useTranslatedStrings } from "../../../../../../hooks/translate";
import { deviceHasSpecialConfig } from "../../../../../../utils/device-utils";
import DeviceIcon from "./components/device-basic-info/components/device-icon";
import DeviceBasicInfo from "./components/device-basic-info/device-basic-info";
import DeviceCharts from "./components/device-charts/device-charts";
import DeviceCustomConfig from "./components/device-custom-config/device-custom-config";
import DeviceDataUsage from "./components/device-data-usage";
import DeviceGoogleDriveLogs from "./components/device-drive-logs/device-drive-logs";

interface Props {
  device: Device;
  memoryUsed: number;
  onEdit: () => void;
  onDelete: () => void;
}
export default function DeviceInfoDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const { device, onDelete, onEdit } = props;
  const deviceHasIntegrations =
    device.integrations &&
    device.integrations.filter((i) => i.enabled).length > 0;
  return (
    <Box bgcolor={"secondary.main"}>
      <DialogTitle variant="h2">
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <DeviceIcon deviceType={device.type} />
          <Box sx={{ width: 10 }} />
          <Typography variant="h2">
            {device ? device?.name : strings.device}
          </Typography>
        </Box>
      </DialogTitle>
      <Box sx={{ maxWidth: "400px" }}>
        <DialogContent dividers>
          <DeviceBasicInfo device={props.device} />
        </DialogContent>

        {(deviceHasSpecialConfig(device.type) || deviceHasIntegrations) && (
          <DialogContent dividers>
            <DeviceCustomConfig device={props.device} />
          </DialogContent>
        )}

        {props.memoryUsed > 100 && (
          <DialogContent dividers>
            <DeviceDataUsage memoryUsed={props.memoryUsed} />
          </DialogContent>
        )}

        <DialogContent dividers>
          <DeviceCharts device={props.device} />
        </DialogContent>

        <DialogContent dividers>
          <DeviceGoogleDriveLogs />
        </DialogContent>

        <DialogActions>
          <Button onClick={onEdit}>{strings.edit}</Button>
          <Button onClick={onDelete} color={"error"}>
            {strings.delete}
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}
