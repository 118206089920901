/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface State {
  language: string;
  user: User | null;
  authToken: string | null;
  wsAuthToken: string | null;
  theme: string;
}

const initialState: State = {
  language: "EN",
  user: null,
  authToken: null,
  wsAuthToken: null,
  theme: "light",
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    loggedIn: (state, action) => {
      state.authToken = action.payload;
    },
    loggedOut: (state) => {
      state.authToken = null;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    setWsAuthToken: (state, action) => {
      state.wsAuthToken = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loggedOut,
  loggedIn,
  setTheme,
  setLanguage,
  setUser,
  setAuthToken,
  setWsAuthToken,
} = appSlice.actions;

export default appSlice.reducer;
