import { useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { setAddingItem } from "../../../../slices/devices-slice";
import AddEditDeviceDialogContent from "../add-edit-device/add-edit-device";
import AddEditGroupDialogContent from "../add-edit-group/add-edit-group";
import SelectAddItemType from "./components/select-item-type";

export default function AddItemDialog() {
  const dispatch = useAppDispatch();
  const open = useAppSelector((state) => state.devicesPage.addingItem);
  const [itemType, setItemType] = useState<string | undefined>();

  const handleClose = () => {
    dispatch(setAddingItem(false));
    setItemType(undefined);
  };

  return (
    <Dialog open={open}>
      <Box sx={{ position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      </Box>
      {itemType == "device" && (
        <AddEditDeviceDialogContent onClose={handleClose} />
      )}
      {itemType == "group" && (
        <AddEditGroupDialogContent onClose={handleClose} />
      )}
      {!itemType && <SelectAddItemType onSelect={setItemType} />}
    </Dialog>
  );
}
