import { useCallback, useContext, useState } from "react";

import CircularSlider from "@fseehawer/react-circular-slider";
import { Bedtime, Thermostat } from "@mui/icons-material";
import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Lottie from "lottie-react";

import client from "../../../../../../../../../../../api/api";
import animationSetpointReached from "../../../../../../../../../../../assets/animations/check.json";
import animationHeating from "../../../../../../../../../../../assets/animations/fire.json";
import { DeviceContext } from "../../../../../../device-context";

interface LabelProps {
  setpoint: number | undefined;
  current: number;
  setPointReached?: boolean;
  isheating?: boolean;
  isCooling?: boolean;
  isOff?: boolean;

  scale: string;
}
function TemperatureControlLabels(props: LabelProps) {
  const {
    setpoint,
    current,
    isheating,
    isCooling,
    isOff,
    setPointReached,
    scale,
  } = props;
  return (
    <>
      <Box
        sx={{
          height: "100%",
          position: "absolute",
          top: "0",
          left: "0",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          userSelect: "none",
          zIndex: 1,
        }}
      >
        <Box sx={{ display: "flex", paddingLeft: "10px" }}>
          <Typography variant="h1">{setpoint?.toFixed(1) ?? "--.-"}</Typography>
          <Typography variant="h5" sx={{ color: "grey" }}>
            ° {scale}
          </Typography>
        </Box>
        <Box
          sx={{
            height: "100%",
            position: "absolute",
            top: "0",
            left: "0",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 1,
            paddingTop: "60px",
          }}
        >
          {isheating && (
            <Lottie
              animationData={animationHeating}
              style={{ height: 50, width: 80, marginTop: "15px" }}
            />
          )}
          {isOff && (
            <Bedtime sx={{ margin: "20px 0 0 15px", fontSize: "30px" }} />
          )}
          {setPointReached && !isheating && !isCooling && !isOff && (
            <Lottie
              loop={false}
              animationData={animationSetpointReached}
              style={{ height: 50, width: 80, marginTop: "15px" }}
            />
          )}
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "0",
          right: "-40px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Thermostat sx={{ fontSize: 12 }} />
        <Typography variant="h5">{current?.toFixed(1) ?? "--.-"}</Typography>
        <Typography variant="h5" sx={{ color: "grey" }}>
          ° {scale}
        </Typography>
      </Box>
    </>
  );
}

export default function TemperatureControl() {
  const deviceContext = useContext(DeviceContext);
  const device = deviceContext?.device ?? {};

  const { config } = device;
  const { scale } = config;
  const step = 0.5;
  const min = scale === "C" ? 10 : 50;
  const max = scale === "C" ? 40 : 104;

  const numbers = Array.from(Array(Math.ceil((max - min) / step)).keys());
  const data = numbers.map((val) => `${(val * step + min).toFixed(1)}`);
  const [value, setValue] = useState(device.setpoint);
  const isOn = device.powerState == "ON";
  const temperature = device.temperature ?? 0;
  const [mode, setMode] = useState(device.mode);

  const calculateInitialIndex = () => {
    const val = device.setpoint ?? 0;
    if (val < min) return 0;
    if (val > max) return data.length - 1;

    const index = (val - min) / step;

    return index;
  };

  const onsSetpointChange = (val: string) => {
    setValue(Number(val));
  };

  const onIsDraggingChange = (val: boolean) => {
    if (!val && value) {
      sendSetpoint(value);
    }
    if (mode == "off") {
      sendMode();
    }
  };

  const sendSetpoint = useCallback(
    async (value: number) => {
      const response = await client.apiV4DirectiveDeviceDeviceIdPut(
        deviceContext?.device?._id ?? "",
        {
          source: "web",
          directive: { field: "setpoint", value: `${value}` },
        },
      );
      if (response.status == 200) {
        // setTimeout(getDevice, 2000);
      }
    },
    [deviceContext?.device],
  );

  const sendMode = useCallback(async () => {
    const response = await client.apiV4DirectiveDeviceDeviceIdPut(
      deviceContext?.device?._id ?? "",
      {
        source: "web",
        directive: { field: "mode", value: `heat` },
      },
    );
    if (response.status == 200) {
      setMode("heat");
    }
  }, [deviceContext?.device]);

  return (
    <Card sx={{ display: "flex", justifyContent: "center", padding: "20px" }}>
      <CircularSlider
        width={200}
        renderLabelValue={
          <TemperatureControlLabels
            scale={scale}
            setpoint={value}
            current={temperature}
            isheating={isOn && mode == "heat"}
            isCooling={isOn && mode == "cool"}
            setPointReached={!isOn && mode != "off"}
            isOff={!isOn && mode === "off"}
          />
        }
        valueFontSize="2rem"
        data={data}
        isDragging={onIsDraggingChange}
        dataIndex={calculateInitialIndex()}
        knobColor="#ee8003"
        knobPosition="bottom"
        progressColorFrom={mode == "heat" ? "#E1B27B" : "#78450286"}
        progressColorTo="#EA983B"
        progressSize={24}
        trackColor={mode == "heat" ? "#eeeeee" : "#858585"}
        trackSize={24}
        onChange={onsSetpointChange}
      />
    </Card>
  );
}
