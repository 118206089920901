import { Box, CircularProgress } from "@mui/material";

import MiniDrawer from "../../components/app-drawer/app-drawer";
import { useAppSelector } from "../../hooks/redux";
import { useTranslatedStrings } from "../../hooks/translate";
import { RootState } from "../../store";
import BasicInformation from "./components/basic-info";
import IntegrationsInformation from "./components/integrations-info/integrations-info";

export default function AccountPage() {
  const strings = useTranslatedStrings();
  const user = useAppSelector((state: RootState) => state.app.user);

  return (
    <MiniDrawer title={strings.account}>
      {user ? (
        <Box>
          <BasicInformation />
          <IntegrationsInformation />
        </Box>
      ) : (
        <CircularProgress color="primary" />
      )}
    </MiniDrawer>
  );
}
