import { useCallback } from "react";

import { Box } from "@mui/material";
import { useQueryClient } from "react-query";
import offIcon from "src/assets/images/device-type-icons/switch-off.svg";
import onIcon from "src/assets/images/device-type-icons/switch.svg";

import api from "../../../../../../../../../api/api";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function SwitchDevice(props: Props) {
  const queryClient = useQueryClient();

  const { device } = props;
  const isOn = device.powerState == "ON";
  const deviceId = device._id ?? "";
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";

  const onclick = useCallback(async () => {
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "powerState", value: isOn ? "OFF" : "ON" },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, isOn, queryClient]);

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />
      <Box
        sx={{
          paddingLeft: "20px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <img src={isOn ? onIcon : offIcon} width={"60"} />
      </Box>
    </Box>
  );
}
