import Particles from "react-tsparticles";

import { useAppSelector } from "../hooks/redux";
import { RootState } from "../store";

export default function PageBackground() {
  const theme = useAppSelector((state: RootState) => state.app.theme);
  const backgroundColor = theme === "dark" ? "#242424" : "#eeeeee";
  const particlesColor = theme === "dark" ? "#121212" : "#b9b9b9";
  const linesColor = theme === "dark" ? "#353535" : "#b9b9b9";
  return (
    <div className="page-bg">
      <Particles
        id="tsparticles"
        options={{
          background: {
            color: {
              value: backgroundColor,
            },
          },
          fpsLimit: 60,
          detectRetina: true,
          particles: {
            number: {
              value: 30,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: particlesColor,
            },
            shape: {
              type: "edge",
              stroke: {
                width: 1,
                color: particlesColor,
              },
              polygon: {
                nb_sides: 5,
              },
              image: {
                src: "img/github.svg",
                width: 100,
                height: 100,
              },
            },
            opacity: {
              value: 0.5,
              random: false,
              anim: {
                enable: false,
                speed: 1,
                opacity_min: 0.1,
                sync: false,
              },
            },
            size: {
              value: 10,
              random: true,
              anim: {
                enable: true,
                speed: 1,
                size_min: 0.1,
                sync: false,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: linesColor,
              opacity: 0.4,
              width: 0.9469771699587272,
            },
            move: {
              enable: true,
              speed: 0.5,
              direction: "bottom",
              random: true,
              straight: true,
              out_mode: "out",
              bounce: false,
              attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200,
              },
            },
          },
        }}
      />
    </div>
  );
}
