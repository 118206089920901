import { Chip } from "@mui/material";

import {
  chartTypes,
  getChartButtonIcon,
  getChartButtonLabel,
} from "../../../../../../../../../../../utils/chart-utils";

interface Props {
  chartType: chartTypes;
  enabled: boolean;
  onClick: () => void;
}

export default function ChartTypeButton(props: Props) {
  const Icon = getChartButtonIcon(props.chartType);
  const label = getChartButtonLabel(props.chartType);

  return (
    <Chip
      sx={{ margin: "5px 2px" }}
      onClick={props.onClick}
      color="primary"
      label={label}
      icon={<Icon />}
      variant={props.enabled ? "filled" : "outlined"}
      clickable
    />
  );
}
