import { useState } from "react";

import { Box, Typography } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../../hooks/translate";
import {
  chartTypes,
  chartTypesForDeviceType,
} from "../../../../../../../../utils/chart-utils";
import AnalogValueChart from "./components/analog-value-chart";
import BatteryChart from "./components/battery-chart";
import ChartVisibilitySelection from "./components/chart-visibility-selection/chart-visibility-selection";
import DetectionStateChart from "./components/detection-state-chart";
import HumidityChart from "./components/humidity-chart";
import LockedStateChart from "./components/locked-state-chart";
import OnlineChart from "./components/online-chart";
import PercentageChart from "./components/percentage-chart";
import PowerStateChart from "./components/power-state-chart";
import SetpointChart from "./components/setpoint-chart";
import TemperatureChart from "./components/temperature-chart";

interface Props {
  device: Device;
}
export default function DeviceCharts(props: Props) {
  const deviceType = props.device.type ?? "switch";
  const deviceId = props.device._id;
  const strings = useTranslatedStrings();
  const [chartsEnabled, setChartsEnabled] = useState<chartTypes[]>([]);

  if (!deviceId) return null;

  const deviceChartTypes = chartTypesForDeviceType(deviceType);

  return (
    <Box>
      <Typography variant="h4">{strings.charts}:</Typography>
      <ChartVisibilitySelection
        supportedValues={deviceChartTypes}
        values={chartsEnabled}
        onChange={setChartsEnabled}
      />
      {chartsEnabled.includes(chartTypes.online) && (
        <OnlineChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.powerState) && (
        <PowerStateChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.temperature) && (
        <TemperatureChart deviceId={deviceId} scale={props.device.scale} />
      )}
      {chartsEnabled.includes(chartTypes.humidity) && (
        <HumidityChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.battery) && (
        <BatteryChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.detectionState) && (
        <DetectionStateChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.lockedState) && (
        <LockedStateChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.analogValue) && (
        <AnalogValueChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.percentage) && (
        <PercentageChart deviceId={deviceId} />
      )}
      {chartsEnabled.includes(chartTypes.setpoint) && (
        <SetpointChart
          deviceId={deviceId}
          units={"°" + (props.device.config?.scale ?? "")}
        />
      )}
    </Box>
  );
}
