import { Box, Typography } from "@mui/material";
import icon from "src/assets/images/device-type-icons/water-meter.svg";

import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function WaterMeterDevice(props: Props) {
  const { device } = props;
  const { total } = device;
  const { config } = device;
  const { units } = config;

  const formattedTotal = total?.toFixed(2) ?? "---.-";

  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        borderRadius: "5px",
      }}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <img style={{}} src={icon} width={"60"} />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "15px", marginTop: "10px" }}
        >
          {formattedTotal} {units}
        </Typography>
      </Box>
    </Box>
  );
}
