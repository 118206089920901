import { useState } from "react";

import { Close } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { useQueryClient } from "react-query";

import client from "../../../../api/api";
import LoadingIndicator from "../../../../components/loading-indicator";
import SmartnestSnackbar from "../../../../components/smartnest-snackbar";
import { useDeviceQuery } from "../../../../hooks/query";
import { useAppDispatch } from "../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../hooks/translate";
import { setSelectedDeviceId } from "../../../../slices/devices-slice";
import AddEditDeviceDialogContent from "../add-edit-device/add-edit-device";
import ConfirmDeleteDeviceDialogContent from "./components/confirm-delete/confirm-delete-device";
import { DeviceContext } from "./components/device-context";
import DeviceInfoDialogContent from "./components/device-info/device-info";

interface Props {
  deviceId?: string | null;
}

export default function DeviceDetailsDialog(props: Props) {
  const queryClient = useQueryClient();
  const dispatch = useAppDispatch();
  const { deviceId } = props;
  const [memoryUsed] = useState(0);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { status, data } = useDeviceQuery(deviceId ?? "");
  const [error, setError] = useState(false);

  const open = deviceId != null;

  const strings = useTranslatedStrings();

  const handleClose = () => {
    setEditing(false);
    setDeleting(false);
    dispatch(setSelectedDeviceId(null));
  };

  const handleDeleteConfirmed = async () => {
    if (!deviceId) return;
    const result = await client.apiV4DevicesDeviceIdDelete(deviceId);
    if (result.status == 200) {
      handleClose();
    } else {
      setError(true);
      handleClose();
    }
  };

  const getChild = () => {
    const device = data;
    const isLoading = status == "loading";

    if (!device || isLoading) {
      return <LoadingIndicator />;
    } else if (editing) {
      return (
        <AddEditDeviceDialogContent onClose={handleClose} device={device} />
      );
    } else if (deleting) {
      return (
        <ConfirmDeleteDeviceDialogContent
          device={device}
          onCancel={() => setDeleting(false)}
          onConfirm={handleDeleteConfirmed}
        />
      );
    }

    return (
      <DeviceInfoDialogContent
        device={device}
        memoryUsed={memoryUsed}
        onEdit={() => setEditing(true)}
        onDelete={() => setDeleting(true)}
      />
    );
  };

  const refreshDeviceData = async () => {
    queryClient.invalidateQueries(["devices", `${deviceId}`]);
  };

  const device = data;
  return (
    <DeviceContext.Provider
      value={{ device, refreshDevice: refreshDeviceData }}
    >
      <SmartnestSnackbar open={error} severity="error">
        {strings.operationNotCompleted}
      </SmartnestSnackbar>
      <Dialog open={open}>
        <Box sx={{ position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </Box>
        {getChild()}
      </Dialog>
    </DeviceContext.Provider>
  );
}
