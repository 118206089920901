import { useCallback, useEffect, useState } from "react";

import { Close } from "@mui/icons-material";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";

import client from "../../../../../../../../../../../../../../api/api";
import LoadingIndicator from "../../../../../../../../../../../../../../components/loading-indicator";
import { useTranslatedStrings } from "../../../../../../../../../../../../../../hooks/translate";
import DeviceListItem from "./components/device-list-item";

interface Props {
  onSelect: (deviceId: string) => void;
  onClose: () => void;
}
export default function SelectDeviceDialog(props: Props) {
  const strings = useTranslatedStrings();
  const [devices, setDevices] = useState<Device[]>([]);
  const [deviceId, setDeviceId] = useState<string>();
  const [loading, setLoading] = useState<boolean>(true);
  const [open, setOpen] = useState<boolean>(true);

  const getDevices = useCallback(async () => {
    const response = await client.apiV4DevicesGet(null);
    if (response.status == 200) {
      setDevices(response.data.devices ?? []);
      setLoading(false);
    }
  }, []);

  const onConfirm = async () => {
    if (!deviceId) return;
    props.onSelect(deviceId);
    setOpen(false);
  };

  const onCancel = async () => {
    props.onClose();
    setOpen(false);
  };

  useEffect(() => {
    getDevices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getChild = () => {
    if (loading) {
      return <LoadingIndicator />;
    }

    return (
      <Box bgcolor={"secondary.main"}>
        <DialogTitle variant="h2">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h2">{strings.devices}</Typography>
          </Box>
        </DialogTitle>
        <Box width={400}>
          <DialogContent dividers>
            <Box sx={{ maxHeight: "200px", overflowY: "auto" }}>
              {devices.map((dev) => (
                <DeviceListItem
                  key={dev._id}
                  device={dev}
                  selected={deviceId === dev._id}
                  onClick={() => setDeviceId(dev._id)}
                />
              ))}
            </Box>
          </DialogContent>

          <DialogActions>
            <Button onClick={onCancel} color={"error"}>
              {strings.cancel}
            </Button>
            <Button onClick={onConfirm}>{strings.confirm}</Button>
          </DialogActions>
        </Box>
      </Box>
    );
  };

  return (
    <Dialog open={open}>
      <Box sx={{ position: "relative" }}>
        <IconButton
          aria-label="close"
          onClick={onCancel}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Box>
      {getChild()}
    </Dialog>
  );
}
