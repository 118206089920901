import React from "react";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { createRoot } from "react-dom/client";
import { QueryClient, QueryClientProvider } from "react-query";
import "./styles/bootstrap.min.css";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import App from "./app";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { store } from "./store";
import "./styles/colors.css";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_VERSION,
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENVIRONMENT ?? "production",
  tracesSampleRate: 1.0,
});

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById("root")!;
const root = createRoot(container); // createRoot(container!) if you use TypeScript
const queryClient = new QueryClient();
root.render(
  <React.StrictMode>
    <Router>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <App />
        </Provider>
        <ReactQueryDevtools initialIsOpen />
      </QueryClientProvider>
    </Router>
  </React.StrictMode>,
);

serviceWorkerRegistration.register();
