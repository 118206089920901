export function getGroupIconName(groupType: string) {
  switch (groupType) {
    case "balcony":
      return "balcony-icon";
    case "basement":
      return "basement-icon";
    case "bathroom":
      return "bathroom-icon";
    case "bedroom":
      return "bedroom-icon";
    case "corridor":
      return "corridor-icon";
    case "default":
      return "default-group-icon";
    case "dinning":
      return "dinning-icon";
    case "gamesroom":
      return "gamesroom-icon";
    case "garage":
      return "garage-icon";
    case "guestroom":
      return "guestroom-icon";
    case "kidsroom":
      return "kidsroom-icon";
    case "kitchen":
      return "kitchen-icon";
    case "laundry":
      return "laundry-icon";
    case "livingroom":
      return "livingroom-icon";
    case "storage":
      return "storage-icon";
    case "workshop":
      return "workshop-icon";
  }
}

export function getGroupIconType(groupIconName?: string) {
  switch (groupIconName) {
    case "balcony-icon":
      return "balcony";
    case "basement-icon":
      return "basement";
    case "bathroom-icon":
      return "bathroom";
    case "bedroom-icon":
      return "bedroom";
    case "corridor-icon":
      return "corridor";
    case "default-group-icon":
      return "default";
    case "dinning-icon":
      return "dinning";
    case "gamesroom-icon":
      return "gamesroom";
    case "garage-icon":
      return "garage";
    case "guestroom-icon":
      return "guestroom";
    case "kidsroom-icon":
      return "kidsroom";
    case "kitchen-icon":
      return "kitchen";
    case "laundry-icon":
      return "laundry";
    case "livingroom-icon":
      return "livingroom";
    case "storage-icon":
      return "storage";
    case "workshop-icon":
      return "workshop";
    default:
      return "default";
  }
}

export const groupTypes = [
  "default",
  "balcony",
  "basement",
  "bathroom",
  "bedroom",
  "corridor",
  "dinning",
  "gamesroom",
  "garage",
  "guestroom",
  "kidsroom",
  "kitchen",
  "laundry",
  "livingroom",
  "storage",
  "workshop",
];
