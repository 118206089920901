import Lottie from "lottie-react";

import animation from "../../../assets/animations/success.json";

import "./success-dialog.css";

interface Props {
  message: string;
}

export default function SuccessDialog(props: Props) {
  return (
    <div className="success-message">
      <div className="icon">
        <Lottie animationData={animation} loop={true} />
      </div>
      <div className="message">{props.message}</div>
    </div>
  );
}
