import React from "react";

import Lottie from "lottie-react";

import animation from "../assets/animations/loading.json";

import "./loading-indicator.css";

export default function LoadingIndicator({ size = 100 }: { size?: number }) {
  return (
    <div className="loading-indicator-container">
      <div style={{ width: size, height: size }}>
        <Lottie animationData={animation} loop={true} />
      </div>
    </div>
  );
}
