export function validateUsername(username: string | null) {
  const regex = /^(?=.{2,100}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/;
  return username != null && regex.test(username);
}

export function validatePassword(password: string | null) {
  const regex = /^(?=.*\d)(?=.*[a-z]).{8,}$/;
  return password != null && regex.test(password);
}

export function validateEmail(email: string | null) {
  const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
  return email != null && regex.test(email);
}
