import { Check } from "@mui/icons-material";
import { Box, Typography, Chip } from "@mui/material";

import client from "../../../../../api/api";
import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import useTranslate, {
  useTranslatedStrings,
} from "../../../../../hooks/translate";
import { setUser } from "../../../../../slices/app-slice";
import {
  getIntegrationDocuUrl,
  getIntegrationLogo,
} from "../../../../../utils/integration-utils";

interface Props {
  enabled: boolean;
  name: string;
}

export default function IntegrationListItem(props: Props) {
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const dispatch = useAppDispatch();
  const language = useAppSelector((state) => state.app.language);
  const { name, enabled } = props;

  const refreshUserIntegrations = async () => {
    const result = await client.apiV4GetMeGet();
    if (result.status == 200) {
      dispatch(setUser(result.data));
    }
  };

  const handleEnableIntegration = async () => {
    if (name === "zigbee2mqtt") {
      const result = await client.apiV4IntegrationsIntegrationNamePut(name, {
        enabled: true,
      });
      if (result.status == 200) {
        refreshUserIntegrations();
      }
    }
    const url = getIntegrationDocuUrl(name, language);
    window.open(url, "_blank");
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        paddingBottom: "10px",
        borderBottom: "1px solid #AFAFAF1F",
      }}
    >
      <Box>
        <img src={getIntegrationLogo(name)} height={40} width={40} />
      </Box>
      <Box sx={{ flex: 2, marginLeft: "10px" }}>
        <Typography> {translate(name)}</Typography>
      </Box>
      <Box>
        {enabled ? (
          <Chip
            color="success"
            label={strings.active}
            icon={<Check />}
            variant="outlined"
          />
        ) : (
          <Chip
            onClick={handleEnableIntegration}
            color="primary"
            label={strings.enable}
            variant="outlined"
            clickable
          />
        )}
      </Box>
    </Box>
  );
}
