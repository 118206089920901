import { useState } from "react";

import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

// import ControlTypeSelection from "./components/control-type-selection";
import ModeSelection from "./components/mode-selection";
import ScaleSelection from "./components/scale-selection";
import TemperatureControl from "./components/temperature-control";

export default function ThermostatCustomConfig() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <TemperatureControl />
      {showMore && (
        <Box>
          <ModeSelection />
          <ScaleSelection />
          {/* <ControlTypeSelection /> */}
        </Box>
      )}
      <Box sx={{ textAlign: "center" }}>
        <IconButton onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </IconButton>
      </Box>
    </>
  );
}
