import Lottie from "lottie-react";

import animation from "../../assets/animations/maintenance.json";
import "./service-unavailable-page.css";
import { useTranslatedStrings } from "../../hooks/translate";

export default function UnavailablePage() {
  const strings = useTranslatedStrings();

  return (
    <div className="service-unavailable-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>

      <div className="page-dialog animated pulse">
        <div className="text-center">
          <div id="title">{strings.serviceUnavailable}</div>
          <div id="message">{strings.serviceUnavailableMessage}</div>
          <Lottie animationData={animation} loop style={{ height: 200 }} />
        </div>
      </div>
    </div>
  );
}
