import { useEffect } from "react";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../store";
import { FormSelector } from "./components/form-selector";

import "./login-page.css";

const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA_V3_ID ?? "";

export default function LoginPage() {
  const authToken = useAppSelector((state: RootState) => state.app.authToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      setTimeout(() => navigate("/devices"), 1500);
    }
  }, [authToken, navigate]);

  return (
    <div className="smartnest-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>
      <div className="login-dialog animated pulse">
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
          <FormSelector />
        </GoogleReCaptchaProvider>
      </div>
    </div>
  );
}
