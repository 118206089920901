import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import icon from "src/assets/images/device-type-icons/motion-sensor.svg";

import animation from "../../../../../../../../../assets/animations/motion-sensor.json";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function MotionSensorDevice(props: Props) {
  const { device } = props;
  const isDetected = device.detectionState == true;

  const backgroundColor = isDetected ? "white" : "rgb(51, 51, 51)";
  const strings = useTranslatedStrings();

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DeviceWidgetTitle device={device} />

      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 2 }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isDetected ? (
            <Lottie animationData={animation} style={{ width: 60 }} />
          ) : (
            <img src={icon} width={"60"} />
          )}
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "15px", marginTop: "10px" }}
        >
          {isDetected ? strings.detected : strings.idle}
        </Typography>
      </Box>
    </Box>
  );
}
