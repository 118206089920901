import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

interface Props {
  label: string;
  open: boolean;
  children: JSX.Element;

  onClick: () => void;
}
export default function AppDrawerItem(props: Props) {
  return (
    <ListItem
      key={props.label}
      disablePadding
      sx={{ display: "block" }}
      onClick={props.onClick}
    >
      <ListItemButton
        sx={{
          minHeight: 48,
          justifyContent: props.open ? "initial" : "center",
          px: 2.5,
        }}
      >
        <ListItemIcon
          sx={{
            minWidth: 0,
            mr: props.open ? 3 : "auto",
            justifyContent: "center",
          }}
        >
          {props.children}
        </ListItemIcon>
        <ListItemText
          primary={props.label}
          sx={{ opacity: props.open ? 1 : 0 }}
        />
      </ListItemButton>
    </ListItem>
  );
}
