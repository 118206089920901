import { useState } from "react";

import {
  Alert,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Snackbar,
  Typography,
} from "@mui/material";

import { useAppSelector } from "../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../hooks/translate";
import { RootState } from "../../../../store";
import { integrationTypes } from "../../../../utils/integration-utils";
import IntegrationListItem from "./components/integration-list-item";

export default function IntegrationsInformation() {
  const user = useAppSelector((state: RootState) => state.app.user);
  const integrations = user?.integrations ?? [];

  const strings = useTranslatedStrings();
  const [open, setOpen] = useState(false);
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          {strings.saved}
        </Alert>
      </Snackbar>
      <Card
        sx={{
          minWidth: 275,
          maxWidth: 700,
          margin: "10px auto",
          padding: "10px",
        }}
      >
        <Typography variant="h3">{strings.integrations}</Typography>
        <CardContent>
          <Box>
            {integrationTypes.map((name) => {
              const integration = integrations.filter(
                (integration) => integration.name === name,
              )[0];
              return (
                <IntegrationListItem
                  key={name}
                  name={name}
                  enabled={integration?.enabled ?? false}
                />
              );
            })}
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: "flex-end" }}>
          <Button size="small" onClick={() => setOpen(true)}>
            {strings.save}
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
