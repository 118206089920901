import TopicAnalogStatusChart from "./base-charts/topic-analog-status-chart";

interface Props {
  deviceId: string;
  units: string;
}
export default function SetpointChart(props: Props) {
  return (
    <TopicAnalogStatusChart
      deviceId={props.deviceId}
      topic={"setpoint"}
      color="#BE710D"
      units={props.units}
    />
  );
}
