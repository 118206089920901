import { Box } from "@mui/material";
import icon from "src/assets/images/device-type-icons/camera.svg";

import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function CameraDevice(props: Props) {
  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        borderRadius: "5px",
      }}
    >
      <DeviceWidgetTitle device={props.device} />
      <Box
        sx={{
          paddingLeft: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          marginTop: "10px",
        }}
      >
        <img style={{}} src={icon} width={"60"} />
      </Box>
    </Box>
  );
}
