import { useCallback } from "react";

import { Box, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import openIcon from "src/assets/images/device-type-icons/door-open.svg";
import closedIcon from "src/assets/images/device-type-icons/door.svg";

import api from "../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function DoorDevice(props: Props) {
  const queryClient = useQueryClient();

  const { device } = props;
  const isOpen = !device.lockedState;

  const strings = useTranslatedStrings();
  const deviceId = device._id ?? "";
  const backgroundColor = isOpen ? "white" : "rgb(51, 51, 51)";

  const onclick = useCallback(async () => {
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "lockedState", value: isOpen ? "true" : "false" },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, isOpen, queryClient]);

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 2 }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={isOpen ? openIcon : closedIcon} width={"60"} />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "15px", marginTop: "10px" }}
        >
          {isOpen ? strings.open : strings.closed}
        </Typography>
      </Box>
    </Box>
  );
}
