import { useEffect, useState } from "react";

import { useParams } from "react-router-dom";

import client from "../../api/api";
import LoadingIndicator from "../../components/loading-indicator";
import ErrorMessage from "./components/error-message";
import PasswordForm from "./components/password-form";
import "./password-reset-page.css";

export default function PasswordResetPage() {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const checkRequestId = async () => {
      const id = params.id;
      if (!id) {
        setLoading(false);
        return;
      }
      const result = await client.passwordResetStatusIdGet(id);
      setLoading(false);
      if (result.status == 200) {
        setLoading(false);
      } else {
        setError(true);
        setLoading(false);
      }
    };
    checkRequestId().catch((error) => {
      setError(error);
    });
  }, [params]);

  return (
    <div className="smartnest-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>
      <div className="page-dialog animated pulse">
        {loading && <LoadingIndicator />}
        {error && <ErrorMessage />}
        {!error && !loading && <PasswordForm />}
      </div>
    </div>
  );
}
