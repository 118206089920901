import { Box, Typography } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../../hooks/translate";
import PhotoBoothCustomConfig from "./components/photo-booth/photo-booth";
import SwitchGroupConfig from "./components/switch-group/switch-group";
import ThermostatCustomConfig from "./components/thermostat/thermostat";
import Zigbee2MqttChildCustomConfig from "./components/zigbee2mqtt/zigbee2mqtt-child";
import Zigbee2MqttParentCustomConfig from "./components/zigbee2mqtt/zigbee2mqtt-parent";

interface Props {
  device: Device;
}
export default function DeviceCustomConfig(props: Props) {
  const strings = useTranslatedStrings();
  const { device } = props;

  const getDeviceConfig = () => {
    const type = device.type;
    switch (type) {
      case "contactSensor":
        return <div>Contact sensor</div>;
      case "door":
        return <div>Door</div>;
      case "doorbell":
        return <div>Doorbell</div>;
      case "lock":
        return <div>Lock</div>;
      case "fan":
        return <div>Fan</div>;
      case "switchGroup":
      case "lightGroup":
        return <SwitchGroupConfig />;
      case "thermostat":
        return <ThermostatCustomConfig />;
      case "zigbee2mqtt":
        return <Zigbee2MqttParentCustomConfig />;
      case "photobooth":
        return <PhotoBoothCustomConfig />;
      case "temperatureSensor":
      case "motionSensor":
      default:
        return null;
    }
  };

  const integrationConfig = (integration: Integration) => {
    const { name } = integration;
    switch (name) {
      case "zigbee2mqtt":
        return integration.config?.parentId ? (
          <Zigbee2MqttChildCustomConfig key={name} />
        ) : null;
    }
  };

  const getIntegrationConfigs = () => {
    const { integrations } = device;
    return integrations?.map(integrationConfig);
  };

  return (
    <Box>
      <Typography variant="h4" sx={{ marginBottom: "10px" }}>
        {strings.deviceConfiguration}:
      </Typography>
      {getIntegrationConfigs()}
      {getDeviceConfig()}
    </Box>
  );
}
