import React, { useCallback, useEffect, useState } from "react";

import * as Sentry from "@sentry/browser";
import { Routes, Route } from "react-router-dom";

import client from "./api/api";
import LoadingIndicator from "./components/loading-indicator";
import { useQuerys } from "./hooks/query";
import { useAppDispatch, useAppSelector } from "./hooks/redux";
import AccountPage from "./pages/account/account-page";
import ConfirmEmailPage from "./pages/confirm-email/confirm-email-page";
import DevicesPage from "./pages/devices/devices-page";
import GoogleDrive from "./pages/google-drive/google-photos";
import GooglePhotos from "./pages/google-photos/google-photos";
import LoginPage from "./pages/login/login-page";
import NotFoundPage from "./pages/not-found/not-found-page";
import PasswordResetPage from "./pages/password-reset/password-reset-page";
import UnavailablePage from "./pages/service-unavailable/service-unavailable-page";
import SplashPage from "./pages/splash/splash-page";
import SystemPage from "./pages/system/system-page";
import { getCookie, removeCookie } from "./services/cookies";
import { setAppTheme } from "./services/theme";
import {
  setLanguage,
  setAuthToken,
  setTheme,
  setUser,
  setWsAuthToken,
} from "./slices/app-slice";
import { RootState } from "./store";

import "./styles/app.css";

export default function App() {
  const dispatch = useAppDispatch();
  const authToken = useAppSelector((state: RootState) => state.app.authToken);

  const [loading, setLoading] = useState(true);

  const query = useQuerys();
  const cookieAuthToken = getCookie("authToken");
  const cookieTheme = getCookie("theme");

  const queryLang = query.get("lang");
  const lang = queryLang || getCookie("lang");

  const checkAuthToken = useCallback(async () => {
    if (authToken) {
      client.setToken(authToken);
      const response = await client.apiV4GetMeGet();

      if (response.status == 200) {
        const user = response.data;
        Sentry.setUser({ email: user.email, id: user._id });
        dispatch(setUser(user));

        const responseWs = await client.apiV4WsTokenGet();
        const newWsToken = responseWs.data.token;
        dispatch(setWsAuthToken(newWsToken));
      } else {
        removeCookie("authToken");
        dispatch(setAuthToken(null));
        dispatch(setWsAuthToken(null));
      }
    }
    setLoading(false);
  }, [authToken, dispatch]);

  useEffect(() => {
    checkAuthToken();
  }, [checkAuthToken]);

  useEffect(() => {
    dispatch(setLanguage(lang));
    dispatch(setAuthToken(cookieAuthToken));
    dispatch(setTheme(cookieTheme));
    setAppTheme(cookieTheme);
  }, [dispatch, lang, cookieTheme, cookieAuthToken]);

  if (loading) return <LoadingIndicator />;

  return (
    <Routes>
      <Route path="/" element={<SplashPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/signup" element={<LoginPage />} />
      <Route path="/confirm_email/:id" element={<ConfirmEmailPage />} />
      <Route path="/reset_password/:id" element={<PasswordResetPage />} />
      <Route path="/service_unavailable" element={<UnavailablePage />} />
      <Route path="/google_photos" element={<GooglePhotos />} />
      <Route path="/google_drive" element={<GoogleDrive />} />
      <Route path="/charts" element={<NotFoundPage />} />
      <Route path="/routines" element={<NotFoundPage />} />
      <Route path="/devices" element={<DevicesPage />} />
      <Route path="/devices/:id" element={<DevicesPage />} />
      <Route path="/account" element={<AccountPage />} />
      <Route path="/system" element={<SystemPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
}
