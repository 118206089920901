import { Box } from "@mui/material";

import { getDeviceIcon } from "../../../../../../../../../utils/device-utils";

interface Props {
  deviceType: Device["type"];
  size?: number;
}
export default function DeviceIcon(props: Props) {
  const { deviceType } = props;
  const defaultSize = 40;
  const icon = getDeviceIcon(deviceType);
  const size = props.size ?? defaultSize;
  return (
    <Box
      bgcolor={"background.paper"}
      borderRadius={size / 2}
      width={size}
      height={size}
      padding={"5px"}
      textAlign="center"
    >
      <img src={icon} width={size - 15} height={size - 15} />
    </Box>
  );
}
