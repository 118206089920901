import AirConditionerDevice from "./devices/air-conditioner/air-conditioner";
import AnalogSensorDevice from "./devices/analog-sensor/analog-sensor";
import BlindsDevice from "./devices/blinds/blinds";
import CameraDevice from "./devices/camera/camera";
import ContactSensorDevice from "./devices/contact-sensor/contact-sensor";
import DoorDevice from "./devices/door/door";
import DoorbellDevice from "./devices/doorbell/doorbell";
import ElectricityMeterDevice from "./devices/electricity-meter/electricity-meter";
import FanDevice from "./devices/fan/fan";
import GasMeterDevice from "./devices/gas-meter/gas-meter";
import LightGroupDevice from "./devices/light-group/light-group";
import LightRgbDevice from "./devices/light-rgb/light-rgb";
import LightDevice from "./devices/light/light";
import LockDevice from "./devices/lock/lock";
import MotionSensorDevice from "./devices/motion-sensor/motion-sensor";
import PhotoBoothDevice from "./devices/photo-booth/photo-booth";
import SprinklerDevice from "./devices/sprinkler/sprinkler";
import SwitchGroupDevice from "./devices/switch-group/switch-group";
import SwitchDevice from "./devices/switch/switch";
import TemperatureSensorDevice from "./devices/temperature-sensor/temperature-sensor";
import ThermostatDevice from "./devices/thermostat/thermostat";
import TvDevice from "./devices/tv/tv";
import VacuumCleanerDevice from "./devices/vacuum-cleaner/vacuum-cleaner";
import WaterMeterDevice from "./devices/water-meter/water-meter";
import Zigbee2MqttDevice from "./devices/zigbee2mqtt/zigbee2mqtt";

interface Props {
  device: Device;
}
export default function DeviceTypeWidget(props: Props) {
  const { device } = props;
  const type = device.type;
  switch (type) {
    case "contactSensor":
      return <ContactSensorDevice device={device} />;
    case "door":
      return <DoorDevice device={device} />;
    case "doorbell":
      return <DoorbellDevice device={device} />;
    case "lock":
      return <LockDevice device={device} />;
    case "blinds":
      return <BlindsDevice device={device} />;
    case "fan":
      return <FanDevice device={device} />;
    case "motionSensor":
      return <MotionSensorDevice device={device} />;
    case "camera":
      return <CameraDevice device={device} />;
    case "tv":
      return <TvDevice device={device} />;
    case "temperatureSensor":
      return <TemperatureSensorDevice device={device} />;
    case "analogSensor":
      return <AnalogSensorDevice device={device} />;
    case "airConditioner":
      return <AirConditionerDevice device={device} />;
    case "thermostat":
      return <ThermostatDevice device={device} />;
    case "switch":
      return <SwitchDevice device={device} />;
    case "switchGroup":
      return <SwitchGroupDevice device={device} />;
    case "sprinkler":
      return <SprinklerDevice device={device} />;
    case "light":
      return <LightDevice device={device} />;
    case "lightGroup":
      return <LightGroupDevice device={device} />;
    case "electrometer":
      return <ElectricityMeterDevice device={device} />;
    case "watermeter":
      return <WaterMeterDevice device={device} />;
    case "gasmeter":
      return <GasMeterDevice device={device} />;
    case "lightRgb":
      return <LightRgbDevice device={device} />;
    case "zigbee2mqtt":
      return <Zigbee2MqttDevice device={device} />;
    case "vacuumCleaner":
      return <VacuumCleanerDevice device={device} />;
    case "photobooth":
      return <PhotoBoothDevice device={device} />;
    default:
      return <div>Unknown Widget</div>;
  }
}
