import { useEffect, useState } from "react";

import { Box, Button } from "@mui/material";
import Typography from "@mui/material/Typography";

import client from "../../api/api";
import MiniDrawer from "../../components/app-drawer/app-drawer";
import { useTranslatedStrings } from "../../hooks/translate";

export default function SystemPage() {
  const strings = useTranslatedStrings();
  const [loggers, setLoggers] = useState();
  const [brokerLoggers, setBrokerLoggers] = useState();

  const getLoggers = async () => {
    const response = await client.apiV4SystemLoggersGet();
    if (response.status === 200 && response.data.loggers) {
      setLoggers(response.data.loggers);
      setBrokerLoggers(response.data.brokerLoggers);
    }
  };
  const setLoggerLevel = async (name: string, level: number) => {
    const response = await client.apiV4SystemLoggersPut({ name, level });
    if (response.status === 200) {
      getLoggers();
    }
  };

  useEffect(() => {
    getLoggers();
  }, []);

  const loggerNames = Object.keys(loggers ?? {});
  const brokerLoggerNames = Object.keys(brokerLoggers ?? {});

  return (
    <MiniDrawer title={strings.system}>
      <Box sx={{ maxWidth: "500px", padding: "10px" }}>
        <Typography variant="h2">Server Loggers</Typography>
        <Box sx={{ height: "30px" }} />

        {loggers &&
          loggerNames.map((logger) => {
            return (
              <LoggerItem
                key={`${logger}-server`}
                name={logger}
                level={loggers[logger]}
                onChange={(newLevel) => setLoggerLevel(logger, newLevel)}
              />
            );
          })}

        <Box sx={{ height: "50px" }} />
        <Typography variant="h2">Broker Loggers</Typography>
        <Box sx={{ height: "30px" }} />

        {brokerLoggers &&
          brokerLoggerNames.map((logger) => {
            return (
              <LoggerItem
                key={`${logger}-broker`}
                name={logger}
                level={brokerLoggers[logger]}
                onChange={(newLevel) => setLoggerLevel(logger, newLevel)}
              />
            );
          })}
      </Box>
    </MiniDrawer>
  );
}

interface Props {
  name: string;
  onChange: (level: number) => void;
  level: number;
}

function LoggerItem(props: Props) {
  const { level, name, onChange } = props;
  const getVariant = (lvl: number) => (level === lvl ? "contained" : "text");

  return (
    <Box sx={{ display: "flex" }}>
      <Typography variant="body1">{name}</Typography>
      <Box sx={{ flex: 2 }} />
      <Button variant={getVariant(0)} color="error" onClick={() => onChange(0)}>
        Error
      </Button>
      <Button
        variant={getVariant(1)}
        color="warning"
        onClick={() => onChange(1)}
      >
        Warning
      </Button>
      <Button variant={getVariant(2)} color="info" onClick={() => onChange(2)}>
        Info
      </Button>
      <Button
        variant={getVariant(3)}
        color="secondary"
        onClick={() => onChange(3)}
      >
        Extra
      </Button>
    </Box>
  );
}
