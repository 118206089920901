import Lottie from "lottie-react";
import { Link } from "react-router-dom";

import animation from "../../assets/animations/not-found.json";
import { useTranslatedStrings } from "../../hooks/translate";

export default function NotFoundPage() {
  const strings = useTranslatedStrings();

  return (
    <div className="smartnest-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>
      <div className="login-dialog animated pulse">
        <h3>{strings.pageNotFound}</h3>
        <div className="my-3">
          <Lottie animationData={animation} loop={true} />
        </div>
        <Link to="/">
          <button className="btn smartnest-button" type="button">
            {strings.home}
          </button>
        </Link>
      </div>
    </div>
  );
}
