import { Box, LinearProgress, Typography } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../hooks/translate";

interface Props {
  activationUntil: string;
}
export default function DeviceSubscriptionInfo(props: Props) {
  const { activationUntil } = props;
  const strings = useTranslatedStrings();

  const until = new Date(activationUntil);
  const from = new Date(activationUntil);
  from.setFullYear(until.getFullYear() - 1);
  const today = new Date();

  const totalPeriod = until.getTime() - from.getTime();
  const eleapsedPeriod = today.getTime() - from.getTime();

  const percentageUsed = (100 * eleapsedPeriod) / totalPeriod;

  return (
    <Box>
      <Typography variant="h4">{strings.subscription}</Typography>
      <Box sx={{ position: "relative", marginTop: "10px" }}>
        <Box sx={{ width: "100%", mr: 1 }}></Box>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography variant="h6">{strings.from}</Typography>
            <Typography variant="h6">{from.toLocaleDateString()}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "end",
              flexDirection: "column",
            }}
          >
            <Typography variant="h6">{strings.until}</Typography>
            <Typography variant="h6">{until.toLocaleDateString()}</Typography>
          </Box>
        </Box>
        <Box height={10} />
        <LinearProgress variant="determinate" value={percentageUsed} />
      </Box>
    </Box>
  );
}
