import { Box, Button } from "@mui/material";

interface Props {
  value: number;
  onChange: (n: number) => void;
}
export default function SwitchControlGateCount(props: Props) {
  const { value, onChange } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button onClick={() => onChange(value - 1)} disabled={value <= 2}>
        -
      </Button>
      <Box>{props.value}</Box>
      <Button onClick={() => onChange(value + 1)} disabled={value >= 8}>
        +
      </Button>
    </Box>
  );
}
