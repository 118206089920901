import { useCallback } from "react";

import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useQueryClient } from "react-query";
import icon from "src/assets/images/device-type-icons/doorbell.svg";

import api from "../../../../../../../../../api/api";
import animation from "../../../../../../../../../assets/animations/doorbell.json";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function DoorbellDevice(props: Props) {
  const queryClient = useQueryClient();

  const { device } = props;
  const isDetected = device.detectionState == true;

  const deviceId = device._id ?? "";
  const backgroundColor = isDetected ? "white" : "rgb(51, 51, 51)";
  const strings = useTranslatedStrings();

  const onclick = useCallback(async () => {
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "detectionState", value: "true" },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, queryClient]);

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />

      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 2 }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {isDetected ? (
            <Lottie animationData={animation} style={{ width: 60 }} />
          ) : (
            <img src={icon} width={"60"} />
          )}
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "15px", marginTop: "10px" }}
        >
          {isDetected ? strings.detected : ""}
        </Typography>
      </Box>
    </Box>
  );
}
