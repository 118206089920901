import * as React from "react";

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import diyIcon from "src/assets/images/icons/diy.svg";
import logo from "src/assets/images/logo.svg";

import { useTranslatedStrings } from "../../../../../hooks/translate";

interface Props {
  onSelect: (mode?: string) => void;
}
export default function SelectNewDeviceKind(props: Props) {
  const strings = useTranslatedStrings();

  return (
    <Box>
      <DialogTitle variant="h2">{strings.addItem}</DialogTitle>
      <DialogContent dividers>
        <Box>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.onSelect("regular")}
          >
            <Box sx={{ display: "flex", width: "250px" }}>
              <img src={logo} alt="regular" height={50} />
              <Box sx={{ width: "20px" }} />
              <Box sx={{ textAlign: "start" }}>
                <Typography variant="body1">{strings.regularDevice}</Typography>
                <Typography variant="h6">
                  {strings.regularDeviceDescription}
                </Typography>
              </Box>
            </Box>
          </Button>
          <Box height={20} />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.onSelect("diy")}
          >
            <Box sx={{ display: "flex", width: "250px" }}>
              <img src={diyIcon} alt="diy" height={50} />
              <Box sx={{ width: "20px" }} />
              <Box sx={{ textAlign: "start" }}>
                <Typography variant="body1">{strings.diyDevice}</Typography>
                <Typography variant="h6">
                  {strings.diyDeviceDescription}
                </Typography>
              </Box>
            </Box>
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
}
