import { useContext } from "react";

import { Warning } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

import client from "../../../../../../../api/api";
import { useAppSelector } from "../../../../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../../../../hooks/translate";
import { maximumStoragePerDeviceInMegaBytes } from "../../../../../../../utils/user-plan-utils";
import { DeviceContext } from "../../device-context";

interface Props {
  memoryUsed: number;
}
export default function DeviceDataUsage(props: Props) {
  const { memoryUsed } = props;
  const user = useAppSelector((state) => state.app.user);
  const planType = user?.plan?.type;
  const strings = useTranslatedStrings();
  const deviceContext = useContext(DeviceContext);

  const memoryUsedString = () => {
    if (memoryUsed < 1000) {
      return `${memoryUsed.toFixed(0)} kB`;
    } else {
      const value = memoryUsed / 1000;
      return `${value.toFixed(2)} MB`;
    }
  };
  const onDeleteDataPressed = async () => {
    await client.apiV4DeviceDataDeviceIdDelete(
      deviceContext?.device?._id ?? "",
    );
    deviceContext?.refreshDevice;
  };

  const dataLimit = maximumStoragePerDeviceInMegaBytes(planType);
  const currentStorageSize = memoryUsed / 1000;
  const percentageUsed = (100 * currentStorageSize) / dataLimit;

  return (
    <Box>
      <Typography variant="h4">{strings.storageUsage}</Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "10px",
        }}
      >
        <Typography variant="h5">
          {strings.memoryUsed}: {memoryUsedString()}
        </Typography>
        <Box sx={{ position: "relative" }}>
          <CircularProgress variant="determinate" value={percentageUsed} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: "absolute",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="caption"
              component="div"
              color="text.secondary"
            >{`${percentageUsed.toFixed(0)}%`}</Typography>
          </Box>
        </Box>
      </Box>
      {percentageUsed >= 100 && (
        <Box
          sx={{
            margin: "20px 0",
            display: "flex",
            alignItems: "center",
            border: "1px solid #A70B0B61",
            borderRadius: "5px",
            padding: "4px",
            backgroundColor: "#B6B6B661",
          }}
        >
          <Warning sx={{ margin: "10px", fill: "#A70B0B61" }} />
          <Box>
            <Typography
              variant="h5"
              sx={{ color: "#A70B0B61", margin: "10px 0" }}
            >
              {strings.maxAllowedMemoryUsed}
            </Typography>
            <Typography
              variant="h5"
              sx={{ color: "#0C0C0C61", marginBottom: "10px" }}
            >
              {strings.maxAllowedMemoryUsedInstructions}
            </Typography>
          </Box>
        </Box>
      )}
      <Button variant="text" onClick={onDeleteDataPressed}>
        {strings.deleteDeviceData}
      </Button>
    </Box>
  );
}
