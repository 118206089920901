import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import client from "../../../../../../../../../../api/api";
import ChartControls from "./components/chart-controls/chart-controls";
import DailyChart from "./daily-chart";

interface Props {
  deviceId: string;
  topic: string;
  color: string;
  units: string;
}
export default function TopicDailyChart(props: Props) {
  const [data, setData] = useState<(number | undefined)[]>([]);
  const [date, setDate] = useState(new Date());
  const startOfTheDay = new Date(date);
  startOfTheDay.setHours(0);
  startOfTheDay.setMinutes(0);
  const endOfTheDay = new Date(date);
  endOfTheDay.setHours(23);
  endOfTheDay.setMinutes(59);

  const getData = async () => {
    setData([]);
    const result = await client.apiV4DeviceDataGetListDeviceIdPut(
      props.deviceId,
      {
        topic: props.topic,
        from: startOfTheDay.getTime(),
        to: endOfTheDay.getTime(),
      },
    );
    if (result.status === 200) {
      processData(result.data.data ?? []);
    }
  };

  const processData = (receivedData: DeviceDataItem[]) => {
    const newData = [];
    for (let i = 0; i < receivedData.length; i++) {
      const item = receivedData[i];
      const dateItem = new Date(item.t ?? 0);
      const hour = dateItem.getHours();

      newData[hour] = item.v;
    }
    setData(newData);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const { color, units } = props;
  return (
    <Box
      sx={{ border: "solid 1px #70707037", borderRadius: "5px", margin: "2px" }}
    >
      <DailyChart color={color} data={data} units={units} />
      <ChartControls
        date={date}
        onDateChanged={(newDate) => setDate(newDate)}
      />
    </Box>
  );
}
