import { useCallback } from "react";

import { Box, Typography } from "@mui/material";
import { useQueryClient } from "react-query";
import closedIcon from "src/assets/images/device-type-icons/blinds-closed.svg";
import semiClosedIcon from "src/assets/images/device-type-icons/blinds-semi-closed.svg";
import openIcon from "src/assets/images/device-type-icons/blinds.svg";

import api from "../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function BlindsDevice(props: Props) {
  const queryClient = useQueryClient();

  const { device } = props;
  const percentage = device.percentage;
  const strings = useTranslatedStrings();
  const deviceId = device._id ?? "";
  const backgroundColor = percentage ? "white" : "rgb(51, 51, 51)";

  const onclick = useCallback(async () => {
    const value = percentage ? "0" : "100";
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "percentage", value },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, queryClient, percentage]);

  const getIcon = () => {
    if (percentage == 0) {
      return closedIcon;
    } else if (percentage == 100) {
      return openIcon;
    } else {
      return semiClosedIcon;
    }
  };

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 2 }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={getIcon()} width={"60"} />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Box sx={{ textAlign: "end" }}>
          <Typography
            variant="h2"
            sx={{ color: "grey", margin: "10px 15px 0 0" }}
          >
            {percentage ? strings.open : strings.closed}
          </Typography>
          <Typography variant="h5" sx={{ color: "grey", marginRight: "15px" }}>
            {percentage}%
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
