import { useCallback, useContext } from "react";

import { Card, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../api/api";
import { useAppSelector } from "../../../../../../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../device-context";

export default function GoogleDriveAccountLinking() {
  const deviceContext = useContext(DeviceContext);

  const strings = useTranslatedStrings();
  const user = useAppSelector((state) => state.app.user);
  const integrations = user?.integrations ?? [];
  const googleDriveIntegration = integrations.filter(
    (i) => i.name === "googleDrive",
  )[0];
  const deviceGoogleDriveIntegration = (
    deviceContext?.device?.integrations ?? []
  ).filter((i) => i.name === "googleDrive")[0];
  const linked = googleDriveIntegration?.reportToken?.refreshToken != null;
  const logFileId = deviceGoogleDriveIntegration?.config.sheetId;

  const onLinkAccountPressed = useCallback(async () => {
    if (linked) {
      //Unlink account
    } else {
      const response = await client.googleDriveStartGet();
      if (response.status == 200 && response.data.url) {
        window.location.href = response.data.url;
      }
    }
  }, [linked]);

  const onCreateFilePressed = useCallback(async () => {
    if (logFileId) {
      window.location.href = `https://docs.google.com/spreadsheets/d/${logFileId}`;
    } else {
      const response = await client.googleDriveCreateSheetPost({
        name: deviceContext?.device?.name,
        deviceId: deviceContext?.device?._id,
      });
      if (response.status == 200 && response.data.id) {
        deviceContext?.refreshDevice();
      }
    }
  }, [deviceContext, logFileId]);

  if (linked) {
    return (
      <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body1">
            {logFileId != null ? strings.logFile : strings.createFile}
          </Typography>
          <Box sx={{ flex: 2 }} />
          <Button onClick={onCreateFilePressed}>
            {logFileId != null ? strings.view : strings.create}
          </Button>
        </Box>
      </Card>
    );
  }

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">{strings.linkedAccount}</Typography>
        <Box sx={{ flex: 2 }} />
        <Button onClick={onLinkAccountPressed}>
          {linked ? strings.unlink : strings.link}
        </Button>
      </Box>
    </Card>
  );
}
