import airConditionerIcon from "../assets/images/device-type-icons/air-conditioner.svg";
import analogSensorIcon from "../assets/images/device-type-icons/analogSensor.svg";
import blindsIcon from "../assets/images/device-type-icons/blinds.svg";
import cameraIcon from "../assets/images/device-type-icons/camera.svg";
import contactSensorIcon from "../assets/images/device-type-icons/contact-sensor.svg";
import doorIcon from "../assets/images/device-type-icons/door.svg";
import doorbellIcon from "../assets/images/device-type-icons/doorbell.svg";
import electrometerIcon from "../assets/images/device-type-icons/electrometer.svg";
import fanIcon from "../assets/images/device-type-icons/fan.svg";
import gasmeterIcon from "../assets/images/device-type-icons/gasmeter.svg";
import lightIcon from "../assets/images/device-type-icons/light.svg";
import lightGroupIcon from "../assets/images/device-type-icons/lightGroup.svg";
import lightRgbIcon from "../assets/images/device-type-icons/lightRgb.svg";
import lockIcon from "../assets/images/device-type-icons/lock.svg";
import motionSensorIcon from "../assets/images/device-type-icons/motion-sensor.svg";
import sprinklerIcon from "../assets/images/device-type-icons/sprinkler.svg";
import switchIcon from "../assets/images/device-type-icons/switch.svg";
import switchGroupIcon from "../assets/images/device-type-icons/switchGroup.svg";
import temperatureSensorIcon from "../assets/images/device-type-icons/temperatureSensor.svg";
import thermostatIcon from "../assets/images/device-type-icons/thermostat.svg";
import tvIcon from "../assets/images/device-type-icons/tv.svg";
import vacuumCleanerIcon from "../assets/images/device-type-icons/vacuum-cleaner.svg";
import watermeterIcon from "../assets/images/device-type-icons/watermeter.svg";
import zigbee2mqttIcon from "../assets/images/device-type-icons/zigbee2mqtt.png";

interface ScreenSize {
  height: number;
  width: number;
}
export function getWidgetSize(screenSize: ScreenSize) {
  const { width, height } = screenSize;
  const smallerSize = height > width ? width : height;

  const maxWidgetSize = 200;
  const padding = 24;
  const spacing = 16;
  const sideBarWidth = 57;
  const calculatedsize =
    (smallerSize - 2 * padding - spacing - sideBarWidth) / 2;
  const size = calculatedsize < maxWidgetSize ? calculatedsize : maxWidgetSize;
  return size;
}

export const deviceTypes = [
  "switch",
  "thermostat",
  "temperatureSensor",
  "airConditioner",
  "analogSensor",
  "blinds",
  "camera",
  "contactSensor",
  "door",
  "doorbell",
  "electrometer",
  "fan",
  "gasmeter",
  "lightGroup",
  "lightRgb",
  "light",
  "lock",
  "motionSensor",
  "sprinkler",
  "switchGroup",
  "tv",
  "vacuumCleaner",
  "watermeter",
];

export const integrationTypes = [
  "alexa",
  "google",
  "siri",
  "ifttt",
  "app",
  "smartthings",
  "espurna",
  "tasmota",
  "homeAssistant",
  "homeBridge",
  "zigbee2mqtt",
];

export const getDeviceIcon = (deviceType: Device["type"]) => {
  switch (deviceType) {
    case "switch":
      return switchIcon;
    case "temperatureSensor":
      return temperatureSensorIcon;
    case "thermostat":
      return thermostatIcon;
    case "zigbee2mqtt":
      return zigbee2mqttIcon;
    case "airConditioner":
      return airConditionerIcon;
    case "analogSensor":
      return analogSensorIcon;
    case "blinds":
      return blindsIcon;
    case "door":
      return doorIcon;
    case "lock":
      return lockIcon;
    case "camera":
      return cameraIcon;
    case "contactSensor":
      return contactSensorIcon;
    case "doorbell":
      return doorbellIcon;
    case "electrometer":
      return electrometerIcon;
    case "fan":
      return fanIcon;
    case "gasmeter":
      return gasmeterIcon;
    case "lightGroup":
      return lightGroupIcon;
    case "lightRgb":
      return lightRgbIcon;
    case "light":
      return lightIcon;
    case "motionSensor":
      return motionSensorIcon;
    case "sprinkler":
      return sprinklerIcon;
    case "switchGroup":
      return switchGroupIcon;
    case "tv":
      return tvIcon;
    case "vacuumCleaner":
      return vacuumCleanerIcon;
    case "watermeter":
      return watermeterIcon;
    default:
      return switchIcon;
  }
};
export const deviceHasSpecialConfig = (deviceType: Device["type"]) => {
  switch (deviceType) {
    case "thermostat":
    case "switchGroup":
    case "lightGroup":
    case "temperatureSensor":
    case "zigbee2mqtt":
    case "photobooth":
      return true;
    default:
      return false;
  }
};

export const defaultUpdateInterval = 30000;
export const defaultDelayAfterDirective = 2000;
