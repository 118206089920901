export const maximumStoragePerDeviceInMegaBytes = (planType?: string) => {
  switch (planType) {
    case "Business":
      return 50;
    case "Pro":
      return 10;
    case "BasicPlus":
      return 10;
    default:
      return 1;
  }
};
