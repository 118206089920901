import { useCallback, useContext, useMemo, useState } from "react";

import { Box, Button, Card, Typography } from "@mui/material";
import { useQueryClient } from "react-query";

import client from "../../../../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../../device-context";
import SwitchControlGateCount from "./components/switch-control-gate-count";
import SwitchGroupGateListItem from "./components/switch-group-gate-list-item";

interface Props {
  onClose: () => void;
}
export default function SwitchGroupGatesConfig(props: Props) {
  const queryClient = useQueryClient();
  const deviceContext = useContext(DeviceContext);
  const strings = useTranslatedStrings();
  const device = useMemo(
    () => deviceContext?.device ?? {},
    [deviceContext?.device],
  );
  const deviceId = device._id ?? "";

  const [gateCount, setGateCount] = useState<number>(
    device.config?.outputs ?? 2,
  );
  const gates = useMemo(() => Array.from(Array(gateCount).keys()), [gateCount]);

  const [names, setNames] = useState<string[]>(
    gates.map((gate, i) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const devJson = device as any;
      return devJson[`name${i + 1}`] ?? `${device.name} ${i + 1}`;
    }),
  );

  const onRename = useCallback(
    async (index: number, name: string) => {
      const newNames = [...names];
      newNames[index] = name;
      setNames(newNames);
    },
    [names],
  );

  const onGateCountChanged = (n: number) => {
    const newNames: string[] = [];
    for (let i = 1; i <= n; i++) {
      newNames.push(names[i - 1] ?? `${device.name} ${i}`);
    }
    setGateCount(n);
    setNames(newNames);
  };

  const onSavePressed = async () => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const config: any = { outputs: gateCount };
    for (let i = 1; i <= gateCount; i++) {
      config[`name${i}`] = names[i - 1];
    }
    const response = await client.apiV4ConfigDeviceDeviceIdPut(deviceId, {
      source: "web",
      config,
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
      props.onClose();
    }
  };

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Typography variant="body1">{strings.devicename}</Typography>
      <Box sx={{ height: "10px" }} />
      <SwitchControlGateCount value={gateCount} onChange={onGateCountChanged} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {names.map((name, i) => (
          <SwitchGroupGateListItem
            key={`dev-${i}`}
            name={name}
            index={i}
            onChange={(name) => onRename(i, name)}
          />
        ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Button onClick={onSavePressed}>{strings.save}</Button>
      </Box>
    </Card>
  );
}
