import { useState } from "react";

import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import AlbumSelection from "./components/album-selection";
import AccountLinking from "./components/google-account-linking";
import PhotoBoothSystemUpdate from "./components/system-update";

export default function PhotoBoothCustomConfig() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <AccountLinking />
      {showMore && (
        <Box>
          <AlbumSelection />
          <PhotoBoothSystemUpdate />
        </Box>
      )}
      <Box sx={{ textAlign: "center" }}>
        <IconButton onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </IconButton>
      </Box>
    </>
  );
}
