export default function SuccessDialog() {
  return (
    <div className="animated bounceIn">
      <img
        className="animated bounceIn"
        src="/img/icons/success.png"
        alt="success"
      />
    </div>
  );
}
