import { useContext, useState } from "react";

import { Check, Link } from "@mui/icons-material";
import { Typography, CircularProgress } from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../../../api/api";
import { useAppDispatch } from "../../../../../../../../../../../../../hooks/redux";
import { setSelectedDeviceId } from "../../../../../../../../../../../../../slices/devices-slice";
import { DeviceContext } from "../../../../../../../../device-context";
import SelectDeviceDialog from "./select-device-dialog/select-device-dialog";

interface Props {
  device: ZigbeeDevice;
  linkedId?: string;
  parentId: string;
}

export default function ZigbeeDeviceItem(props: Props) {
  const { device, linkedId, parentId } = props;
  const [selectDeviceDialogOpen, setSelectDeviceDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const deviceContext = useContext(DeviceContext);
  const dispatch = useAppDispatch();

  const onLinkButtonPressed = () => {
    if (linkedId) {
      dispatch(setSelectedDeviceId(linkedId));
    } else {
      setSelectDeviceDialogOpen(true);
    }
  };

  const handleDeviceSelected = async (deviceId: string) => {
    setLoading(true);
    await client.apiV4IntegrationsZigbee2mqttLinkDevicePut({
      deviceId,
      parentId,
      address: device.address ?? "",
    });
    await deviceContext?.refreshDevice();
    setLoading(false);
  };

  return (
    <Box
      sx={{
        border: "1px #A7A7A75C solid",
        padding: "3px",
        marginTop: "2px",
        borderRadius: "5px",
        display: "flex",
      }}
    >
      <Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="h5">
            {device.definition?.model ?? device.type}
          </Typography>
          <Typography variant="h6" sx={{ opacity: 0.3, marginLeft: "5px" }}>
            {device.address}
          </Typography>
        </Box>
        <Typography variant="h6" sx={{ color: "grey" }}>
          {device.definition?.description ?? device.type}
        </Typography>
      </Box>
      <Box sx={{ flex: 2 }} />
      {loading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            borderTopRightRadius: "3px",
            borderBottomRightRadius: "3px",
            backgroundColor: linkedId ? "#00AC5F61" : "#5B5B5B37",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "60px",
            "&:hover": {
              backgroundColor: linkedId ? "#00AC5F97" : "#71717159",
              cursor: "pointer",
            },
          }}
          onClick={onLinkButtonPressed}
        >
          <Typography variant="h6" sx={{ opacity: 0.5 }}>
            {linkedId ? "Linked" : "Link"}
          </Typography>
          <Box sx={{ width: "5px" }} />

          {linkedId ? (
            <Check sx={{ fontSize: 11 }} />
          ) : (
            <Link sx={{ fontSize: 11 }} />
          )}
        </Box>
      )}

      {selectDeviceDialogOpen ? (
        <SelectDeviceDialog
          onSelect={handleDeviceSelected}
          onClose={() => setSelectDeviceDialogOpen(false)}
        />
      ) : null}
    </Box>
  );
}
