import { Box } from "@mui/material";

import { chartTypes } from "../../../../../../../../../../utils/chart-utils";
import ChartTypeButton from "./components/chart-type-button";

interface Props {
  supportedValues: chartTypes[];
  values: chartTypes[];
  onChange: (newTypes: chartTypes[]) => void;
}
export default function ChartVisibilitySelection(props: Props) {
  const onItemClicked = (chartType: chartTypes) => {
    const newValues = [...props.values];
    if (newValues.includes(chartType)) {
      const index = newValues.indexOf(chartType);
      newValues.splice(index, 1);
    } else {
      newValues.push(chartType);
    }
    props.onChange(newValues);
  };

  return (
    <Box>
      {props.supportedValues.map((chartType) => {
        const enabled = props.values.includes(chartType);
        return (
          <ChartTypeButton
            key={chartType}
            chartType={chartType}
            enabled={enabled}
            onClick={() => onItemClicked(chartType)}
          />
        );
      })}
    </Box>
  );
}
