import { Avatar } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../../hooks/redux";
import { RootState } from "../../../store";
import "./profile-button.css";

export default function ProfileButton() {
  const user = useAppSelector((state: RootState) => state.app.user);
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/account");
  };

  return (
    <div className="profile-button" onClick={onClick}>
      <div className="profile-button__picture">
        <Avatar
          alt="User Name"
          src={user?.profilePictureUrl ?? "/img/profile/default-user.png"}
          sx={{ width: 40, height: 40, margin: "5px" }}
        />
      </div>
      <div className="profile-button__info">
        <div className="profile-button__info__name">{user?.name}</div>
        <div className="profile-button__info__email">{user?.email}</div>
      </div>
    </div>
  );
}
