import { useState } from "react";

import { Alert, Snackbar } from "@mui/material";

interface Props {
  children: string;
  open: boolean;
  severity: "error" | "info" | "success" | "warning";
}
export default function SmartnestSnackbar(props: Props) {
  const { open, severity, children } = props;
  const [closed, setClosed] = useState(false);
  return (
    <Snackbar
      open={open && !closed}
      autoHideDuration={6000}
      onClose={() => setClosed(true)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert
        onClose={() => setClosed(true)}
        severity={severity}
        sx={{ width: "100%" }}
      >
        {children}
      </Alert>
    </Snackbar>
  );
}
