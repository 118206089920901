import * as React from "react";

import {
  Box,
  Button,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import deviceIcon from "src/assets/images/icons/device-color.svg";
import subscriptionIcon from "src/assets/images/icons/subscription.svg";

import client from "../../../../../api/api";
import { useTranslatedStrings } from "../../../../../hooks/translate";

export default function UpgradeMessage() {
  const strings = useTranslatedStrings();

  const getProClicked = async () => {
    startPayment("Smartnest_Pro", "month");
  };

  const getDeviceClicked = () => {
    startPayment("Single_Device_Access", "year");
  };

  const startPayment = async (description: string, period: string) => {
    const result = await client.apiV4PaymentsPost({
      description: description,
      period: period,
    });
    if (result.status == 200 && result.data.url) {
      window.location.href = result.data.url;
    }
  };

  const offerBoxStyle = {
    minWidth: "250px",
    border: "solid 1px #8282827B",
    borderRadius: "5px",
    padding: "10px",
    textAlign: "center",
    margin: "10px",
  };

  return (
    <Box>
      <DialogTitle variant="h2">{strings.deviceLimitReached}</DialogTitle>
      <DialogContent dividers>
        <Box
          sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
        >
          <Box sx={offerBoxStyle}>
            <Typography variant="h2">Get 1 extra device</Typography>
            <img src={deviceIcon} alt="regular" height={50} />
            <Typography variant="h4">4.99 USD / year</Typography>
            <Box sx={{ height: "20px" }} />
            <Button variant="contained" onClick={getDeviceClicked}>
              Buy 1 device
            </Button>
          </Box>
          <Box sx={offerBoxStyle}>
            <Typography variant="h2">Get 50 devices</Typography>
            <img src={subscriptionIcon} alt="regular" height={50} />
            <Typography variant="h4">4.99 USD / month</Typography>
            <Box sx={{ height: "20px" }} />
            <Button variant="contained" onClick={getProClicked}>
              Upgrade to Pro
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Box>
  );
}
