import { useCallback, useEffect } from "react";

import Lottie from "lottie-react";
import { Link } from "react-router-dom";

import client from "../../api/api";
import animation from "../../assets/animations/check.json";
import { useQuerys } from "../../hooks/query";
import { useAppSelector } from "../../hooks/redux";
import { useTranslatedStrings } from "../../hooks/translate";

export default function GoogleDrive() {
  const strings = useTranslatedStrings();
  const query = useQuerys();
  const code = query.get("code");
  const user = useAppSelector((state) => state.app.user);

  const finishLinking = useCallback(() => {
    if (!user) return;
    client.googleDriveFinishPost({ authCode: code ?? "" });
  }, [code, user]);

  useEffect(() => {
    finishLinking();
  }, [finishLinking, user]);

  return (
    <div className="smartnest-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>
      <div className="login-dialog animated pulse">
        <h3>{strings.linked}</h3>
        <div className="my-3">
          <Lottie animationData={animation} loop={false} />
        </div>
        <Link to="/">
          <button className="btn smartnest-button" type="button">
            {strings.home}
          </button>
        </Link>
      </div>
    </div>
  );
}
