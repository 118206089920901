import * as React from "react";

import { Box, Typography } from "@mui/material";

import DeviceIcon from "../../../../../../../../device-basic-info/components/device-icon";

interface Props {
  device: Device;
  selected?: boolean;
  onClick?: () => void;
}
export default function DeviceListItem(props: Props) {
  const { device } = props;
  const { type } = device;
  return (
    <Box
      onClick={props.onClick}
      sx={{
        width: "100%",
        padding: "4px",
        borderRadius: "5px",
        backgroundColor: props.selected ? "#C4C4C442" : null,
        "&:hover": { backgroundColor: "#C4C4C442", cursor: "pointer" },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <DeviceIcon deviceType={type} size={40} />
        <Box width={20} />
        <Typography variant="body1">{device.name}</Typography>
      </Box>
    </Box>
  );
}
