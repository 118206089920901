import "./form-input-field.css";

interface Props {
  type: "username" | "password" | "email" | "name";
  placeholder: string;
  errorMessage: string;
  isValid: boolean | null;
  onChange: (value: string) => void;
}

// eslint-disable-next-line import/no-default-export
export default function FormInputField(props: Props) {
  function getInputMode() {
    switch (props.type) {
      case "email":
        return "email";
      default:
        return "text";
    }
  }

  return (
    <>
      {props.isValid == false && (
        <div className="invalid-message">{props.errorMessage}</div>
      )}
      <input
        type={props.type}
        className={`login-form-field${
          props.isValid == false ? " invalid" : ""
        }`}
        inputMode={getInputMode()}
        autoComplete="on"
        required
        placeholder={props.placeholder}
        title={props.errorMessage}
        onChange={(event) => props.onChange(event.target.value)}
      />
    </>
  );
}
