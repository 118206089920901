import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import TopicBinaryStatusChart from "./base-charts/topic-binary-status-chart";

interface Props {
  deviceId: string;
}
export default function LockedStateChart(props: Props) {
  const strings = useTranslatedStrings();

  return (
    <TopicBinaryStatusChart
      deviceId={props.deviceId ?? ""}
      topic={"lockedState"}
      color="#9F8640"
      valueWhenFalse={strings.unlocked}
      valueWhenTrue={strings.locked}
      heigth={70}
    />
  );
}
