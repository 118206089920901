import { useContext, useState } from "react";

import { LinkOffOutlined } from "@mui/icons-material";
import { Box, CircularProgress, Typography } from "@mui/material";

import client from "../../../../../../../../../../api/api";
import zigbee2mqttIcon from "../../../../../../../../../../assets/images/device-type-icons/zigbee2mqtt.png";
import { useTranslatedStrings } from "../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../device-context";

export default function Zigbee2MqttChildCustomConfig() {
  const deviceContext = useContext(DeviceContext);
  const device = deviceContext?.device ?? {};
  const strings = useTranslatedStrings();
  const [loading, setLoading] = useState(false);

  const onUnlinkButtonPressed = async () => {
    setLoading(true);
    await client.apiV4IntegrationsZigbee2mqttUnlinkDevicePut({
      deviceId: device._id ?? "",
    });
    if (deviceContext?.refreshDevice) {
      await deviceContext?.refreshDevice();
    }
    setLoading(false);
  };

  return (
    <>
      <Box
        sx={{
          border: "1px #A7A7A75C solid",
          padding: "3px",
          marginTop: "2px",
          borderRadius: "5px",
          display: "flex",
        }}
      >
        <img src={zigbee2mqttIcon} alt="zigbee2mqtt" height={40} width={40} />
        <Box width={10} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">{strings.zgbChildDevice}</Typography>
        </Box>
        <Box sx={{ flex: 2 }} />
        {loading ? (
          <CircularProgress />
        ) : (
          <Box
            sx={{
              borderTopRightRadius: "3px",
              borderBottomRightRadius: "3px",
              backgroundColor: "#A70B0B61",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "60px",
              "&:hover": {
                backgroundColor: "#A70B0B9E",
                cursor: "pointer",
              },
            }}
            onClick={onUnlinkButtonPressed}
          >
            <Typography variant="h6" sx={{ opacity: 0.5 }}>
              {strings.unlink}
            </Typography>
            <Box sx={{ width: "5px" }} />

            <LinkOffOutlined sx={{ fontSize: 11 }} />
          </Box>
        )}
      </Box>
    </>
  );
}
