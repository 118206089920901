import { useState } from "react";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useNavigate } from "react-router-dom";

import client from "../../../api/api";
import FormInputField from "../../../components/form-input-field";
import LoadingIndicator from "../../../components/loading-indicator";
import useTranslate, { useTranslatedStrings } from "../../../hooks/translate";
import { validateEmail } from "../../../utils/field-validation";
import SuccessDialog from "./success-dialog";

interface Props {
  onCancelClicked: () => void;
}

export default function ForgotPasswordForm(props: Props) {
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const navigate = useNavigate();

  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  function isFormValid() {
    return validateEmail(email) && recaptcha;
  }

  async function handleSubmitClicked() {
    if (!isFormValid()) {
      setIsInitialState(false);
      return;
    }
    if (email && recaptcha) {
      setloading(true);

      const response = await client.requestPasswordResetPost({
        email: email,
        recaptcha: recaptcha ?? "",
      });

      setloading(false);

      if (response.status === 200) {
        setSuccess(true);
      } else if (response.response.status === 503) {
        navigate("/service_unavailable");
      } else {
        const { data } = response.response;
        setError(data.errorDescriptionKey);
      }
    }
  }

  if (success) return <SuccessDialog message={strings.emailwassent} />;

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <div className="login-dialog-title">{strings.resetpassword}</div>

      {error && <h6 className="error-message">{translate(error)}</h6>}

      <FormInputField
        isValid={isInitialState ? null : validateEmail(email)}
        type="email"
        placeholder={strings.email}
        errorMessage={strings.emailwrongformat}
        onChange={(value) => setEmail(value)}
      />

      <div className="spacer"></div>

      <GoogleReCaptcha onVerify={setRecaptcha} />

      <button
        className="btn smartnest-button"
        type="button"
        onClick={handleSubmitClicked}
      >
        {strings.resetpassword}
      </button>

      <div className="login-dialog-footer" onClick={props.onCancelClicked}>
        <div className="link">{strings.cancel}</div>
      </div>
    </>
  );
}
