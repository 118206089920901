import { useState } from "react";

import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import AccountLinking from "./components/google-drive-account-linking";

export default function DeviceGoogleDriveLogs() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <AccountLinking />
      <Box sx={{ textAlign: "center" }}>
        <IconButton onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </IconButton>
      </Box>
    </>
  );
}
