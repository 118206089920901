import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

import animation from "../../../assets/animations/error.json";
import { useTranslatedStrings } from "../../../hooks/translate";

import "./error-message.css";

export default function ErrorMessage() {
  const strings = useTranslatedStrings();
  const navigate = useNavigate();

  return (
    <div className="error-message">
      <div className="icon">
        <Lottie animationData={animation} loop={false} />
      </div>
      <div className="message">{strings.linkIsNotValid}</div>
      <button
        className="btn smartnest-button"
        type="button"
        onClick={() => navigate("/login")}
      >
        {strings.back}
      </button>
    </div>
  );
}
