import { Box, Button, DialogActions, DialogTitle } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../hooks/translate";

interface Props {
  group: Group;
  onEdit: () => void;
  onDelete: () => void;
}
export default function GroupInfoDialogContent(props: Props) {
  const strings = useTranslatedStrings();
  const { group, onDelete, onEdit } = props;
  return (
    <Box>
      <DialogTitle>{group ? group?.name : strings.group}</DialogTitle>
      <Box width={400}>
        <DialogActions>
          <Button autoFocus onClick={onEdit}>
            Edit
          </Button>
          <Button autoFocus onClick={onDelete} color={"error"}>
            Delete
          </Button>
        </DialogActions>
      </Box>
    </Box>
  );
}
