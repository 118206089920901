import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import useTranslate, {
  useTranslatedStrings,
} from "../../../../../hooks/translate";
import { groupTypes } from "../../../../../utils/group-utils";

interface Props {
  value: string;
  onSelect: (type: string) => void;
}
export default function GroupTypeSelect(props: Props) {
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const handleChange = (event: SelectChangeEvent) => {
    props.onSelect(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel>{strings.type}</InputLabel>
        <Select
          value={props.value}
          label={strings.type}
          onChange={handleChange}
        >
          {groupTypes.map((groupTypes: string) => (
            <MenuItem key={groupTypes} value={groupTypes}>
              {translate(groupTypes)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
