import { FormControl, TextField } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../../../../../../../hooks/translate";

interface Props {
  name: string;
  index: number;
  onChange: (name: string) => void;
}
export default function SwitchGroupGateListItem(props: Props) {
  const { onChange, name, index } = props;
  const strings = useTranslatedStrings();
  return (
    <FormControl sx={{ m: 1, width: "100%" }}>
      <TextField
        id="outlined-basic"
        label={`${strings.devicename} ${index + 1}`}
        variant="outlined"
        value={name}
        onChange={(e) => onChange(e.target.value)}
      />
    </FormControl>
  );
}
