import React, { useCallback } from "react";

import useWebSocket from "react-use-websocket";

import { useAppSelector } from "../hooks/redux";
import { RootState } from "../store";
import { WsContext } from "./ws/ws-context";

const WS_URL = process.env.REACT_APP_WS_URL ?? "";

interface Props {
  children: JSX.Element;
}
export default function WebSocketService(props: Props) {
  const token =
    useAppSelector((state: RootState) => state.app.wsAuthToken) ?? "";
  if (!token) return props.children;

  return <WebSocketWrapper token={token}>{props.children}</WebSocketWrapper>;
}

interface WsProps {
  token: string;
  children: JSX.Element;
}

interface WsCallback {
  deviceId: string;
  callback: (message: MessageEvent) => void;
}

const callbacks: WsCallback[] = [];
function WebSocketWrapper(props: WsProps) {
  const { token } = props;

  const onWsMessage = useCallback((message: MessageEvent) => {
    let json = { type: "", deviceId: "" };
    try {
      json = JSON.parse(message.data);
    } catch (error) {
      console.error("Error parsing ws message");
    }

    for (let i = 0; i < callbacks.length; i++) {
      const callback = callbacks[i];
      if (callback.deviceId == json.deviceId) {
        callback.callback(message);
      }
    }
  }, []);

  const { sendJsonMessage } = useWebSocket(WS_URL, {
    share: true,
    protocols: ["Token", token],
    onError: (e) => console.log("error", e),
    onOpen: () => sendJsonMessage({ message: "hello", type: "new" }),
    onMessage: onWsMessage,
    shouldReconnect: () => true,
  });

  const addListener = (
    deviceId: string,
    callback: (message: MessageEvent) => void,
  ) => {
    const deviceIds = callbacks.map((cb) => cb.deviceId);
    const index = deviceIds.indexOf(deviceId);
    if (index > -1) {
      callbacks.splice(index, 1);
    }
    callbacks.push({ deviceId, callback });
  };

  return (
    <WsContext.Provider
      value={{
        sendMessage: () => sendJsonMessage({ type: "test" }),
        addListener: addListener,
      }}
    >
      {props.children}
    </WsContext.Provider>
  );
}
