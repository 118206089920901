/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

interface UsersState {
  devices: Device[];
  groups: Group[];
  networkError: boolean;
  loading: boolean;
  filteredByGroupId: string | null;
  selectedGroupId: string | null;
  selectedDeviceId: string | null;
  addingItem: boolean;
}

export const sessionInfoSlice = createSlice({
  name: "devices",
  initialState: {
    devices: [],
    groups: [],
    networkError: false,
    loading: true,
    selectedGroupId: null,
    filteredByGroupId: null,
    selectedDeviceId: null,
    addingItem: false,
  } as UsersState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setNetworkError: (state, action) => {
      state.networkError = action.payload;
    },
    setFilteredByGroupId: (state, action) => {
      state.filteredByGroupId = action.payload;
    },
    setSelectedGroupId: (state, action) => {
      state.selectedGroupId = action.payload;
    },
    setSelectedDeviceId: (state, action) => {
      state.selectedDeviceId = action.payload;
    },
    setAddingItem: (state, action) => {
      state.addingItem = action.payload;
    },
    setDevices: (state, action) => {
      state.devices = action.payload;
    },
    setGroups: (state, action) => {
      state.groups = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setLoading,
  setSelectedDeviceId,
  setSelectedGroupId,
  setFilteredByGroupId,
  setGroups,
  setDevices,
  setNetworkError,
  setAddingItem,
} = sessionInfoSlice.actions;

export default sessionInfoSlice.reducer;
