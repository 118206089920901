import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";

import { useAppSelector } from "../../../../../../../../../../hooks/redux";
import { addAlpha } from "../../../../../../../../../../utils/color-utils";

Chart.register(...registerables);

interface Props {
  data: (number | undefined)[];
  color: string;
  units: string;
}
export default function DailyChart(props: Props) {
  const numbers = Array.from(Array(24).keys());
  const labels = numbers.map((num) => `${num <= 9 ? "0" : ""}${num}:00`);
  labels.push("00:00");
  labels.push("01:00");
  const { color, units } = props;
  const lightColor = addAlpha(color, 0.3);
  const strongColor = addAlpha(color, 0.8);

  const theme = useAppSelector((state) => state.app.theme);
  const gridXColor = theme === "dark" ? "#575757" : "#FBFBFB";
  const gridYColor = addAlpha(gridXColor, 0.6);

  return (
    <Line
      data={{
        labels,
        datasets: [
          {
            label: "t",
            data: props.data,
            cubicInterpolationMode: "monotone",
            fill: "start",
          },
        ],
      }}
      options={{
        elements: {
          point: { radius: 2, hoverRadius: 10, backgroundColor: strongColor },
          line: {
            fill: false,
            backgroundColor: lightColor,
            borderColor: color,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (item) => `${item.formattedValue} ${units}`,
            },
          },
        },
        scales: {
          x: {
            border: { display: false },
            ticks: {
              color: "grey",
              maxTicksLimit: 5,
              font: { size: 10, weight: "bold" },
            },
            grid: {
              color: gridXColor,
            },
          },
          y: {
            border: { display: false },
            ticks: {
              color: "#C1C1C1",
              maxTicksLimit: 4,
              font: { size: 8 },
            },
            grid: {
              color: gridYColor,
            },
          },
        },
      }}
    />
  );
}
