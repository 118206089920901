import { useState } from "react";

import { Check, ContentCopy } from "@mui/icons-material";
import { Chip } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";

interface Props {
  content?: string;
  showContent?: boolean;
}
export default function CopyButton(props: Props) {
  const { showContent, content } = props;
  const strings = useTranslatedStrings();
  const [copied, setCopied] = useState(false);
  const onClick = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(content ?? "");
    }
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };
  const label = showContent ? content : strings.copy;
  const iconStyle = { height: 15 };
  return (
    <Chip
      sx={{ margin: "5px 2px", fontSize: 10 }}
      onClick={onClick}
      color="primary"
      label={copied ? strings.copied : label}
      icon={copied ? <Check sx={iconStyle} /> : <ContentCopy sx={iconStyle} />}
      variant={copied ? "filled" : "outlined"}
      clickable
    />
  );
}
