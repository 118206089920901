import {
  Battery0BarOutlined,
  CloudQueue,
  GpsFixed,
  PowerSettingsNew,
  Thermostat,
  Water,
  Animation,
  Lock,
  Speed,
  PercentOutlined,
} from "@mui/icons-material";

export enum chartTypes {
  online,
  powerState,
  temperature,
  humidity,
  battery,
  setpoint,
  lockedState,
  analogValue,
  percentage,
  detectionState,
}

export const chartTypesForDeviceType = (deviceType: Device["type"]) => {
  switch (deviceType) {
    case "temperatureSensor":
      return [
        chartTypes.online,
        chartTypes.temperature,
        chartTypes.humidity,
        chartTypes.battery,
      ];
    case "thermostat":
      return [
        chartTypes.online,
        chartTypes.powerState,
        chartTypes.temperature,
        chartTypes.setpoint,
      ];
    case "switch":
      return [chartTypes.online, chartTypes.powerState];
    case "door":
    case "lock":
      return [chartTypes.online, chartTypes.lockedState];
    case "motionSensor":
    case "contactSensor":
    case "doorbell":
      return [chartTypes.online, chartTypes.detectionState];
    case "zigbee2mqtt":
      return [chartTypes.online];
    case "analogSensor":
      return [chartTypes.online, chartTypes.analogValue];
    case "blinds":
      return [chartTypes.online, chartTypes.percentage];
    default:
      return [chartTypes.online];
  }
};
export const getChartButtonIcon = (chartType: chartTypes) => {
  switch (chartType) {
    case chartTypes.online:
      return CloudQueue;
    case chartTypes.powerState:
      return PowerSettingsNew;
    case chartTypes.temperature:
      return Thermostat;
    case chartTypes.setpoint:
      return GpsFixed;
    case chartTypes.humidity:
      return Water;
    case chartTypes.battery:
      return Battery0BarOutlined;
    case chartTypes.detectionState:
      return Animation;
    case chartTypes.lockedState:
      return Lock;
    case chartTypes.analogValue:
      return Speed;
    case chartTypes.percentage:
      return PercentOutlined;
  }
};
export const getChartButtonLabel = (chartType: chartTypes) => {
  switch (chartType) {
    case chartTypes.online:
      return "online";
    case chartTypes.powerState:
      return "power";
    case chartTypes.temperature:
      return "temperature";
    case chartTypes.setpoint:
      return "setpoint";
    case chartTypes.humidity:
      return "humidity";
    case chartTypes.battery:
      return "battery";
    case chartTypes.detectionState:
      return "detection";
    case chartTypes.lockedState:
      return "lockedState";
    case chartTypes.analogValue:
      return "value";
    case chartTypes.percentage:
      return "percentage";
  }
};
