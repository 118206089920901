import TopicAnalogStatusChart from "./base-charts/topic-analog-status-chart";

interface Props {
  deviceId: string;
}
export default function AnalogValueChart(props: Props) {
  return (
    <TopicAnalogStatusChart
      deviceId={props.deviceId}
      topic={"value"}
      color="#B77126"
      units=""
    />
  );
}
