import { useCallback } from "react";

import { Box } from "@mui/material";
import { useQueryClient } from "react-query";
import offIcon from "src/assets/images/device-type-icons/light-off.svg";
import onIcon from "src/assets/images/device-type-icons/light.svg";

import api from "../../../../../../../../../api/api";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function LightGroupDevice(props: Props) {
  const queryClient = useQueryClient();

  const { device } = props;
  const isOn = device.powerState == "ON";
  const gates = Array.from(Array(device.config?.outputs ?? 2).keys());
  const powerStates = gates.map(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (gate, i) => (device as any)[`powerState${i + 1}`],
  );

  const deviceId = device._id ?? "";
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";

  const onclick = useCallback(async () => {
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "powerState", value: isOn ? "OFF" : "ON" },
    });
    for (let i = 1; i <= gates.length; i++) {
      await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
        source: "web",
        directive: { field: `powerState${i}`, value: isOn ? "OFF" : "ON" },
      });
    }
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, isOn, gates, queryClient]);

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />
      <Box
        sx={{
          height: 80,
          width: gates.length > 4 ? 150 : 80,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {powerStates.map((state, index) => (
          <img
            key={`powerState${index}`}
            src={state == "ON" ? onIcon : offIcon}
            style={{
              minWidth: "10px",
              maxWidth: "40px",
              maxHeight: "30px",
              margin: "3px",
            }}
          />
        ))}
      </Box>
    </Box>
  );
}
