import { useMemo } from "react";

import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";

import client from "../api/api";

export const useQuerys = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

export const useDeviceQuery = (id: string) => {
  return useQuery({
    enabled: id !== "",
    queryKey: ["devices", id],
    staleTime: 1000 * 60 * 5,
    queryFn: async () => {
      const response = await client.apiV4DevicesDeviceIdGet(id);
      return response.data.device;
    },
  });
};
