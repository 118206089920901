import { useCallback, useContext } from "react";

import { Card, Typography, Button } from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../api/api";
import { DeviceContext } from "../../../../../../device-context";

export default function PhotoBoothSystemUpdate() {
  const deviceContext = useContext(DeviceContext);

  const device = deviceContext?.device ?? {};

  const onUpdatePressed = useCallback(async () => {
    await client.apiV4DirectiveDeviceDeviceIdPut(device._id ?? "", {
      directive: { field: "update", value: "" },
      source: "web",
    });
  }, [device._id]);

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">System app</Typography>
        <Box sx={{ flex: 2 }} />
        <Button onClick={onUpdatePressed}>Update</Button>
      </Box>
    </Card>
  );
}
