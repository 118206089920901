import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { AddBox } from "@mui/icons-material";
import {
  Card,
  Typography,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../api/api";
import { useAppSelector } from "../../../../../../../../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../device-context";

export default function AlbumSelection() {
  const strings = useTranslatedStrings();
  const user = useAppSelector((state) => state.app.user);
  const deviceContext = useContext(DeviceContext);

  const device = useMemo(() => deviceContext?.device ?? {}, [deviceContext]);

  const integrations = user?.integrations ?? [];
  const googlePhotosIntegration = integrations.filter(
    (i) => i.name === "googlePhotos",
  )[0];
  const linked = googlePhotosIntegration?.reportToken?.refreshToken != null;
  const [albumId, setAlbumId] = useState(
    device.config.googlePhotosAlbumId ?? "",
  );
  const [albums, setAlbums] = useState<GooglePhotosAlbum[]>([]);

  const onSaveIdPressed = useCallback(async () => {
    if (!linked) return;
    await client.apiV4ConfigDeviceDeviceIdPut(device._id ?? "", {
      config: { googlePhotosAlbumId: albumId },
    });
    deviceContext?.refreshDevice();
  }, [albumId, device, deviceContext, linked]);

  const getAlbums = useCallback(async () => {
    if (!linked) return;
    const response = await client.googlePhotosAlbumsGet();
    setAlbums(response.data.albums ?? []);
  }, [linked]);

  const onCreateAlbumPressed = useCallback(async () => {
    if (!linked) return;
    const response = await client.googlePhotosCreateAlbumPost({
      name: `Fotokoutek ${new Date().toLocaleDateString()}`,
    });
    if (response.status == 200) {
      setAlbumId(response.data?.id ?? "");
      getAlbums();
    }
  }, [getAlbums, linked]);

  useEffect(() => {
    getAlbums();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!linked) return null;

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormControl sx={{ m: 1, minWidth: 250 }}>
          <InputLabel>{"Album"}</InputLabel>
          <Select
            value={albumId}
            label={"Album"}
            onChange={(e) => setAlbumId(e.target.value)}
          >
            {albums.map((album) => (
              <MenuItem key={album.id} value={album.id}>
                <AlbumListItem key={album.id} album={album} />
              </MenuItem>
            ))}
            <MenuItem value={""} onClick={onCreateAlbumPressed}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "10px",
                  height: "80px",
                  width: "80px",
                }}
              >
                <AddBox />
                <Typography variant="h6">New</Typography>
              </Box>
            </MenuItem>
          </Select>
        </FormControl>
        <Button onClick={onSaveIdPressed}>{strings.save}</Button>
      </Box>
    </Card>
  );
}

interface Props {
  album: GooglePhotosAlbum;
}
function AlbumListItem(props: Props) {
  const { album } = props;
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        margin: "10px",
      }}
    >
      <img src={album.coverPhotoBaseUrl} alt="cover" height={50} />
      <Box sx={{ width: 20 }} />
      <Box sx={{ width: 20, overflow: "none", marginRight: "20px" }}>
        <Typography variant="h5">{album.title}</Typography>
      </Box>
    </Box>
  );
}
