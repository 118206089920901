import TopicAnalogStatusChart from "./base-charts/topic-analog-status-chart";

interface Props {
  deviceId: string;
}
export default function PercentageChart(props: Props) {
  return (
    <TopicAnalogStatusChart
      deviceId={props.deviceId}
      topic={"percentage"}
      color="#BAA90F"
      units="%"
    />
  );
}
