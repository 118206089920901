import { useCallback, useContext, useMemo } from "react";

import { Box, Card, Typography } from "@mui/material";
import { useQueryClient } from "react-query";

import client from "../../../../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../../device-context";
import LighthGroupButton from "./components/light-group-button";
import SwitchGroupButton from "./components/switch-group-button";

export default function SwitchGroupControl() {
  const queryClient = useQueryClient();
  const deviceContext = useContext(DeviceContext);
  const strings = useTranslatedStrings();
  const device = useMemo(
    () => deviceContext?.device ?? {},
    [deviceContext?.device],
  );
  const deviceId = device._id ?? "";

  const gates = useMemo(
    () => Array.from(Array(device.config?.outputs ?? 2).keys()),
    [device.config?.outputs],
  );
  const buttonItems = useMemo(
    () =>
      gates.map((gate, i) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const devJson = device as any;
        return {
          powerState: devJson[`powerState${i + 1}`],
          percentage: devJson[`percentage${i + 1}`],
          name: devJson[`name${i + 1}`] ?? `${device.name} ${i + 1}`,
          index: i,
        };
      }),
    [device, gates],
  );

  const onclick = useCallback(
    async (index: number) => {
      const gate = buttonItems[index];
      if (!gate) return;
      const isOn = gate.powerState == "ON";
      const response = await client.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
        source: "web",
        directive: {
          field: `powerState${index + 1}`,
          value: isOn ? "OFF" : "ON",
        },
      });
      if (response.status == 200) {
        queryClient.invalidateQueries(["devices", `${deviceId}`]);
      }
    },
    [buttonItems, deviceId, queryClient],
  );
  const onPercentageChanged = useCallback(
    async (index: number, value: number) => {
      const gate = buttonItems[index];
      if (!gate) return;
      const response = await client.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
        source: "web",
        directive: {
          field: `percentage${index + 1}`,
          value: `${value}`,
        },
      });
      if (response.status == 200) {
        queryClient.invalidateQueries(["devices", `${deviceId}`]);
      }
    },
    [buttonItems, deviceId, queryClient],
  );

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Typography variant="body1">{strings.switchGroup}</Typography>
      <Box sx={{ height: "10px" }} />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {buttonItems.map((gate) => {
          if (device.type == "lightGroup") {
            return (
              <LighthGroupButton
                key={gate.index}
                name={gate.name}
                isOn={gate.powerState == "ON"}
                percentage={gate.percentage}
                index={gate.index}
                onClick={() => onclick(gate.index)}
                onChange={(p) => onPercentageChanged(gate.index, p)}
              />
            );
          }
          return (
            <SwitchGroupButton
              key={gate.index}
              name={gate.name}
              isOn={gate.powerState == "ON"}
              index={gate.index}
              onClick={() => onclick(gate.index)}
            />
          );
        })}
      </Box>
    </Card>
  );
}
