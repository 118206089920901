import { useCallback } from "react";

import { GpsFixed, Thermostat } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import Lottie from "lottie-react";
import { useQueryClient } from "react-query";
import airConditionerIcon from "src/assets/images/device-type-icons/air-conditioner.svg";
import heaterIcon from "src/assets/images/device-type-icons/heater.svg";

import api from "../../../../../../../../../api/api";
import animationCooling from "../../../../../../../../../assets/animations/air-conditioner.json";
import animationHeating from "../../../../../../../../../assets/animations/fire.json";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
  mainMode?: "cool" | "heat";
}
export default function ThermostatDevice(props: Props) {
  const queryClient = useQueryClient();
  const { device } = props;
  const mainMode = props.mainMode ?? "heat";
  const isOn = device.powerState == "ON";

  const deviceId = device._id ?? "";
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";
  const textColor = isOn ? "#808080" : "white";
  const { config } = device;
  const { scale } = config;

  const onclick = useCallback(async () => {
    const response = await api.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: {
        field: "mode",
        value: isOn ? "off" : mainMode ?? "heat",
      },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [deviceId, isOn, mainMode, queryClient]);

  const formattedSetpoint = device.setpoint?.toFixed(1) ?? "--.--";
  const formattedTemperature = device.temperature?.toFixed(1) ?? "--.--";

  const getAnimatedIcon = () => {
    let animation, icon;
    if (mainMode == "heat") {
      animation = animationHeating;
      icon = heaterIcon;
    } else {
      animation = animationCooling;
      icon = airConditionerIcon;
    }

    return (
      <Box
        sx={{
          paddingLeft: "10px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        {isOn ? (
          <Lottie animationData={animation} style={{ width: 60 }} />
        ) : (
          <img style={{}} src={icon} width={"60"} />
        )}
      </Box>
    );
  };

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
      }}
      onClick={onclick}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        {getAnimatedIcon()}
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <GpsFixed
              sx={{
                fontSize: "15px",
                margin: "10px 5px 0 0",
                color: textColor,
              }}
            />
            <Typography
              variant="h2"
              sx={{ color: textColor, marginRight: "15px", marginTop: "10px" }}
            >
              {formattedSetpoint} °{scale}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Thermostat
              sx={{ fontSize: "12px", marginRight: "5px", color: textColor }}
            />
            <Typography
              variant="h5"
              sx={{ color: textColor, marginRight: "15px" }}
            >
              {formattedTemperature} °{scale}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
