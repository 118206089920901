import { useCallback, useContext, useEffect } from "react";

import MoreIcon from "@mui/icons-material/MoreHorizOutlined";
import { Box } from "@mui/material";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import { useQueryClient } from "react-query";

import { useDeviceQuery } from "../../../../../../hooks/query";
import { useAppDispatch } from "../../../../../../hooks/redux";
import useWindowDimensions from "../../../../../../hooks/window-dimensions";
import { WsContext } from "../../../../../../services/ws/ws-context";
import { setSelectedDeviceId } from "../../../../../../slices/devices-slice";
import { getWidgetSize } from "../../../../../../utils/device-utils";
import DeviceTypeWidget from "./components/device-type-widget";

interface Props {
  deviceId: string;
}

export default function DeviceWidget(props: Props) {
  const { height, width } = useWindowDimensions();
  const dispatch = useAppDispatch();
  const size = getWidgetSize({ width, height });
  const { status, data } = useDeviceQuery(props.deviceId);
  const queryClient = useQueryClient();
  const wsContext = useContext(WsContext);

  useEffect(() => {
    if (data?._id) {
      wsContext?.addListener(`${data._id}`, () => {
        queryClient.invalidateQueries(["devices", `${data._id}`]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id]);

  const onMoreButtonClicked = useCallback(() => {
    const device = data;
    if (device) {
      dispatch(setSelectedDeviceId(device?._id));
    }
  }, [data, dispatch]);

  const device = data;

  if (status === "loading") {
    return <div>Loading</div>;
  } else if (status === "error") {
    return <div>Error</div>;
  } else if (!device) {
    return <div>Error</div>;
  }

  return (
    <Grid item className="animate__animated animate__pulse">
      <Paper
        sx={{
          height: size / 1.7,
          width: size,
          backgroundColor: "transparent",
          "&:hover": {
            cursor: "pointer",
            boxShadow:
              "0 14px 28px rgba(100,100,100,0.25), 0 10px 10px rgba(200,200,200,0.5)",
          },
        }}
      >
        <DeviceTypeWidget device={device} />
        <Box
          sx={{
            position: "absolute",
            padding: "7px 15px",
            bottom: 0,
            right: 0,
          }}
        >
          <MoreIcon onClick={onMoreButtonClicked} htmlColor="grey" />
        </Box>
      </Paper>
    </Grid>
  );
}
