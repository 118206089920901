import { useState } from "react";

import { Box, Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment, { Moment } from "moment";

import { useTranslatedStrings } from "../../../../../../../../../../../../../hooks/translate";

interface Props {
  onCancel: () => void;
  onDateSelected: (date: Date) => void;
  open: boolean;
  date: Date;
}

export default function DateSelectionDialog(props: Props) {
  const [date, setDate] = useState(props.date);
  const strings = useTranslatedStrings();

  const onDateChanged = (newDate: Moment | null) => {
    if (newDate) setDate(newDate.toDate());
  };
  const onOkButtonPressed = () => {
    props.onDateSelected(date);
  };

  return (
    <Dialog open={props.open}>
      <DialogTitle>{strings.selectDate}</DialogTitle>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DatePicker
          maxDate={moment()}
          value={moment(date)}
          format="DD/MM/YYYY"
          onChange={onDateChanged}
          sx={{ margin: "20px" }}
        ></DatePicker>
      </LocalizationProvider>
      <Box width={400}>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => props.onCancel()}
            color={"secondary"}
          >
            {strings.cancel}
          </Button>
          <Button
            autoFocus
            onClick={() => onOkButtonPressed()}
            color={"primary"}
          >
            {strings.ok}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
