import { MoreHoriz } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";

import { useAppDispatch, useAppSelector } from "../../../../../hooks/redux";
import { setSelectedGroupId } from "../../../../../slices/devices-slice";

interface Props {
  group: Group;

  onClick: () => void;
}

export default function GroupListItem(props: Props) {
  const { group } = props;
  const dispatch = useAppDispatch();
  const filteredByGroupId = useAppSelector(
    (state) => state.devicesPage.filteredByGroupId,
  );

  const onMoreButtonClicked = () => {
    dispatch(setSelectedGroupId(group._id));
  };

  const selected =
    group._id == filteredByGroupId || (filteredByGroupId === "" && !group._id);
  return (
    <ListItemButton selected={selected} onClick={props.onClick}>
      <ListItemIcon>
        <img
          src={`/img/groupIcons/${group.iconName}.png`}
          alt="icon"
          height={25}
          width={25}
        />
      </ListItemIcon>
      <ListItemText primary={group.name} />
      {group._id && (
        <IconButton size="small" onClick={onMoreButtonClicked}>
          <MoreHoriz />
        </IconButton>
      )}
    </ListItemButton>
  );
}
