import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import TopicBinaryStatusChart from "./base-charts/topic-binary-status-chart";

interface Props {
  deviceId: string;
}
export default function DetectionStateChart(props: Props) {
  const strings = useTranslatedStrings();

  return (
    <TopicBinaryStatusChart
      deviceId={props.deviceId ?? ""}
      topic={"detectionState"}
      color="#DAA514"
      valueWhenFalse={strings.notdetected}
      valueWhenTrue={strings.detected}
      heigth={70}
    />
  );
}
