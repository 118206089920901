// @ts-nocheck
import './models';

const axios = require('axios');

let token = '';

let baseUrl = process.env.REACT_APP_BASE_URL ?? '';

const client = {

  setToken: (value) => { token = value; },
  setBaseUrl: (value) => { baseUrl = value; },

  /**
  * Delete account
  * @returns {Promise<AxiosResponse<AccountDeleteResponse>} undefined - Account was deleted
  */
  accountDelete: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/account`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Store push notifications token
  * @param {PushNotificationsTokenPutBody} body - the login information
  * @returns {Promise<AxiosResponse<PushNotificationsTokenPutResponse>} undefined - Object with result
  */
  pushNotificationsTokenPut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/push_notifications_token`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Confirm email
  * @param {string} confirmationId - null
  * @returns {Promise<AxiosResponse<ConfirmEmailPutResponse>} undefined - Object with result
  */
  confirmEmailConfirmationIdPut: async (confirmationId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/confirm_email/${confirmationId}`);
    return axios.put(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Set profile picture
  * @param {file} image - null
  * @returns {Promise<AxiosResponse<SetProfilePicturePostResponse>} undefined - Object with result
  */
  setProfilePicturePost: async (form) => {
    const encodedURI = window.encodeURI(`${baseUrl}/set_profile_picture`);
    return axios.post(encodedURI, form, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Set account info
  * @param {SetAccountInfoPutBody} body - the User information
  * @returns {Promise<AxiosResponse<SetAccountInfoPutResponse>} undefined - Object with result
  */
  setAccountInfoPut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/set_account_info`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Change password
  * @param {ChangePasswordPutBody} body - the password information
  * @returns {Promise<AxiosResponse<ChangePasswordPutResponse>} undefined - Object with result
  */
  changePassswordPut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/change_passsword`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get confirmation email
  * @returns {Promise<AxiosResponse<ConfirmationEmailGetResponse>} undefined - Object with result
  */
  confirmationEmailGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/confirmation_email`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get User information;
  * @returns {Promise<AxiosResponse<User>} undefined - An object with user info
  */
  apiV4GetMeGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/get_me`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get User information;
  * @returns {Promise<AxiosResponse<V4GetWsTokenResponse>} undefined - A ws token
  */
  apiV4WsTokenGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/ws_token`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create device;
  * @param {V4PostDevicesBody} body - device info
  * @returns {Promise<AxiosResponse<Device>} undefined - Device inserted
  */
  apiV4DevicesPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get user devices;
  * @param {string| null } groupId - if empty returns devices without assigned group, if null returns all user devices
  * @returns {Promise<AxiosResponse<V4GetDevicesResponse>} undefined - object with device list
  */
  apiV4DevicesGet: async (groupId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices?groupId=${groupId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get device by id;
  * @param {string} deviceId - null
  * @returns {Promise<AxiosResponse<V4GetDeviceResponse>} undefined - Device object
  */
  apiV4DevicesDeviceIdGet: async (deviceId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices/${deviceId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Update device;
  * @param {string} deviceId - null
  * @param {V4PutDevicesBody} body - null
  * @returns {Promise<AxiosResponse<Device>} undefined - Device updated
  */
  apiV4DevicesDeviceIdPut: async (deviceId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices/${deviceId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete device by id;
  * @param {string} deviceId - null
  * @returns {Promise<AxiosResponse<object>>} body - result
  */
  apiV4DevicesDeviceIdDelete: async (deviceId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices/${deviceId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create group;
  * @param {V4PostGroupsBody} body - null
  * @returns {Promise<AxiosResponse<Group>} undefined - Group inserted
  */
  apiV4GroupsPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/groups`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Get user groups;
  * @returns {Promise<AxiosResponse<V4GetGroupsResponse>} undefined - object with group list
  */
  apiV4GroupsGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/groups`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get group by id;
  * @param {string} groupId - null
  * @returns {Promise<AxiosResponse<Group>} undefined - Group object
  */
  apiV4GroupsGroupIdGet: async (groupId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/groups/${groupId}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Update group;
  * @param {string} groupId - null
  * @param {V4PutGroupsBody} body - null
  * @returns {Promise<AxiosResponse<Group>} undefined - Group updated
  */
  apiV4GroupsGroupIdPut: async (groupId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/groups/${groupId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Delete group by id;
  * @param {string} groupId - null
  * @returns {Promise<AxiosResponse<object>>} body - result
  */
  apiV4GroupsGroupIdDelete: async (groupId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/groups/${groupId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get device data by topic;
  * @param {string} deviceId - null
  * @param {V4PutDeviceDataGetListBody} body - device data filter
  * @returns {Promise<AxiosResponse<V4PutDeviceDataGetListResponse>} undefined - Data found
  */
  apiV4DeviceDataGetListDeviceIdPut: async (deviceId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/device_data/get_list/${deviceId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Delete device;
  * @param {string} deviceId - null
  * @returns {Promise<AxiosResponse<V4DeleteDeviceDataResponse>} undefined - Data found
  */
  apiV4DeviceDataDeviceIdDelete: async (deviceId) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/device_data/${deviceId}`);
    return axios.delete(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get diy devices adding permission;
  * @returns {Promise<AxiosResponse<V4GetAddDiyDevicesAllowed>} undefined - Object with result
  */
  apiV4DevicesAddDiyDevicesAllowedGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/devices/add_diy_devices_allowed`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start a payment through stripe
  * @param {V4PostPaymentBody} body - null
  * @returns {Promise<AxiosResponse<V4PostPayment>} undefined - Object with system versions
  */
  apiV4PaymentsPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/payments`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Control device;
  * @param {string} deviceId - null
  * @param {V4PutControlDeviceBody} body - null
  * @returns {Promise<AxiosResponse<V4PutControlDeviceResponse>} undefined - Object with result
  */
  apiV4DirectiveDeviceDeviceIdPut: async (deviceId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/directive/device/${deviceId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Control device;
  * @param {string} deviceId - null
  * @param {V4PutConfigDeviceBody} body - null
  * @returns {Promise<AxiosResponse<V4PutConfigDeviceResponse>} undefined - Object with result
  */
  apiV4ConfigDeviceDeviceIdPut: async (deviceId,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/config/device/${deviceId}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Set integration state;
  * @param {string} integrationName - null
  * @param {V4PutIntegrationStateBody} body - null
  * @returns {Promise<AxiosResponse<V4GetSystemStatusResponse>} undefined - Object with result
  */
  apiV4IntegrationsIntegrationNamePut: async (integrationName,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/integrations/${integrationName}`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start google photos authentication
  * @returns {Promise<AxiosResponse<GooglePhotosStartGetResponse>} undefined - object with device list
  */
  googlePhotosStartGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_photos/start`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start google photos authentication
  * @returns {Promise<AxiosResponse<GooglePhotosAlbumsGetResponse>} undefined - object with device list
  */
  googlePhotosAlbumsGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_photos/albums`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start google photos authentication
  * @param {GooglePhotosFinishPostBody} body - auth code
  * @returns {Promise<AxiosResponse<GetGooglePhotosFinishResponse>} undefined - object with device list
  */
  googlePhotosFinishPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_photos/finish`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create album in google photos
  * @param {GooglePhotosCreateAlbumPostBody} body - album information
  * @returns {Promise<AxiosResponse<GooglePhotosCreateAlbumPostResponse>} undefined - object with device list
  */
  googlePhotosCreateAlbumPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_photos/create_album`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start google drive authentication
  * @returns {Promise<AxiosResponse<GoogleDriveStartGetResponse>} undefined - object with device list
  */
  googleDriveStartGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_drive/start`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Start google drive authentication
  * @param {GoogleDriveFinishPostBody} body - auth code
  * @returns {Promise<AxiosResponse<GetGoogleDriveFinishResponse>} undefined - object with device list
  */
  googleDriveFinishPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_drive/finish`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Create sheet in google drive
  * @param {GoogleDriveCreateAlbumPostBody} body - album information
  * @returns {Promise<AxiosResponse<GoogleDriveCreateAlbumPostResponse>} undefined - object with device list
  */
  googleDriveCreateSheetPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/google_drive/create_sheet`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Authenticate with smartnest
  * @param {LoginPostBody} body - the login information
  * @returns {Promise<AxiosResponse<LoginPostResponse>} undefined - Object with session Id
  */
  loginPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/login`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Log out from smartnest
  * @param {LogoutPostBody} body - the logout information
  * @returns {Promise<AxiosResponse<LogoutPostResponse>} undefined - result
  */
  logoutPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/logout`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Register in smartnest
  * @param {SignupPostBody} body - the sigmup information
  * @returns {Promise<AxiosResponse<LoginPostResponse>} undefined - Object with session Id
  */
  signupPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/signup`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Request password reset
  * @param {RequestPasswordResetPostBody} body - null
  * @returns {Promise<AxiosResponse<RequestPasswordResetPostResponse>} undefined - Object with result
  */
  requestPasswordResetPost: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/request_password_reset`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get password reset request status
  * @param {string} id - id of the request
  * @returns {Promise<AxiosResponse<object>>} body - String with the result
  */
  passwordResetStatusIdGet: async (id) => {
    const encodedURI = window.encodeURI(`${baseUrl}/password_reset_status/${id}`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Register in smartnest
  * @param {string} id - null
  * @param {ResetPasswordPostBody} body - null
  * @returns {Promise<AxiosResponse<object>>} body - String with the result
  */
  resetPasswordIdPost: async (id,body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/reset_password/${id}`);
    return axios.post(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get system version;
  * @returns {Promise<AxiosResponse<V4GetSystemVersionResponse>} undefined - Object with system versions
  */
  apiV4SystemVersionGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/system_version`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get system status;
  * @returns {Promise<AxiosResponse<V4GetSystemStatusResponse>} undefined - Object with system status
  */
  apiV4SystemStatusGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/system_status`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Get system loggers;
  * @returns {Promise<AxiosResponse<V4GetSystemLoggersResponse>} undefined - Object with loggers
  */
  apiV4SystemLoggersGet: async () => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/system/loggers`);
    return axios.get(encodedURI, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
  /**
  * Set system logger level;
  * @param {V4PutSystemLogger} body - logger level
  * @returns {Promise<AxiosResponse<V4GetSystemLoggersResponse>} undefined - Object with loggers
  */
  apiV4SystemLoggersPut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/system/loggers`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Link child device to a parent device;
  * @param {V4PutIntegrationsZigbee2mqttLinkDeviceBody} body - request body
  * @returns {Promise<AxiosResponse<V4PutIntegrationsZigbee2mqttLinkDeviceResponse>} undefined - Request succesful
  */
  apiV4IntegrationsZigbee2mqttLinkDevicePut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/integrations/zigbee2mqtt/link_device`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },

  /**
  * Unlink child device from a parent device;
  * @param {V4PutIntegrationsZigbee2mqttUnlinkDeviceBody} body - request body
  * @returns {Promise<AxiosResponse<V4PutIntegrationsZigbee2mqttUnlinkDeviceResponse>} undefined - Request succesful
  */
  apiV4IntegrationsZigbee2mqttUnlinkDevicePut: async (body) => {
    const encodedURI = window.encodeURI(`${baseUrl}/api/v4/integrations/zigbee2mqtt/unlink_device`);
    return axios.put(encodedURI, body, { headers: { authorization: `Bearer ${token}` } })
      .then((response) => response).catch((error) => error);
  },
};
export default client;
