import { useState } from "react";

import { useParams } from "react-router-dom";

import client from "../../../api/api";
import FormInputField from "../../../components/form-input-field";
import LoadingIndicator from "../../../components/loading-indicator";
import useTranslate, { useTranslatedStrings } from "../../../hooks/translate";
import { validatePassword } from "../../../utils/field-validation";
import SuccessDialog from "./success-dialog";

import "./password-form.css";

export default function PasswordForm() {
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const params = useParams();

  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const [password, setPassword] = useState<string | null>(null);
  const [password2, setPassword2] = useState<string | null>(null);

  const [loading, setloading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  function isFormValid() {
    return password && validatePassword(password) && password === password2;
  }

  async function handleSubmitClicked() {
    if (!isFormValid()) {
      setIsInitialState(false);
      return;
    }
    if (password && params.id) {
      setloading(true);
      const result = await client.resetPasswordIdPost(params.id, {
        password: password,
      });
      setloading(false);

      if (result.status == 200) {
        setSuccess(true);
      } else {
        setError(strings.error);
      }
    }
  }

  if (success) return <SuccessDialog />;

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <div className="login-dialog-title">{strings.resetpassword}</div>

      {error && <h6 className="error-message">{translate(error)}</h6>}

      <FormInputField
        isValid={isInitialState ? null : validatePassword(password)}
        type="password"
        placeholder={strings.password}
        errorMessage={strings.passwordhelp}
        onChange={(value) => {
          setIsInitialState(false);
          setPassword(value);
        }}
      />

      <FormInputField
        isValid={
          isInitialState ? null : password2 === password && password2 != null
        }
        type="password"
        placeholder={strings.confirmpassword}
        errorMessage={strings.passwordsdontmatch}
        onChange={(value) => setPassword2(value)}
      />

      <div className="spacer"></div>

      <button
        className="btn smartnest-button"
        type="button"
        onClick={handleSubmitClicked}
      >
        {strings.resetpassword}
      </button>
    </>
  );
}
