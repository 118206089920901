import { useState } from "react";

import ForgotPasswordForm from "./forgot-password-form";
import LoginForm from "./login-form";
import SignupForm from "./signup-form";

export enum FormMode {
  login,
  signup,
  forgotPass,
}

export function FormSelector() {
  const [mode, setMode] = useState(FormMode.login);

  switch (mode) {
    case FormMode.login:
      return (
        <LoginForm
          onForgotClicked={() => setMode(FormMode.forgotPass)}
          onSignupClicked={() => setMode(FormMode.signup)}
        />
      );
    case FormMode.signup:
      return <SignupForm onLoginClicked={() => setMode(FormMode.login)} />;
    case FormMode.forgotPass:
      return (
        <ForgotPasswordForm onCancelClicked={() => setMode(FormMode.login)} />
      );
  }
}
