import { useCallback, useContext } from "react";

import {
  Card,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../device-context";

export default function ModeSelection() {
  const deviceContext = useContext(DeviceContext);
  const device = deviceContext?.device ?? {};
  const strings = useTranslatedStrings();
  const { mode } = device;

  const onModeChanged = useCallback(async (value: string) => {
    await client.apiV4DirectiveDeviceDeviceIdPut(device._id ?? "", {
      directive: { field: "mode", value },
    });
    deviceContext?.refreshDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1"> {strings.mode}</Typography>
        <Box sx={{ flex: 2 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormControl fullWidth>
            <InputLabel>{strings.mode}</InputLabel>
            <Select
              label={strings.mode}
              value={mode}
              onChange={(e) => onModeChanged(e.target.value)}
            >
              <MenuItem value={"off"}>{strings.off}</MenuItem>
              <MenuItem value={"heat"}>{strings.heat}</MenuItem>
              <MenuItem value={"cool"}>{strings.cool}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
    </Card>
  );
}
