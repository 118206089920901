import { AccountTree } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";

import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function Zigbee2MqttDevice(props: Props) {
  const strings = useTranslatedStrings();
  const { device } = props;
  const { integrations } = device;
  const filtered = integrations?.filter((i) => i.name == "zigbee2mqtt");
  if (!filtered || filtered.length == 0) return null;

  const { config } = filtered[0];
  const devices = config["zigbeeDevices"] as ZigbeeDevice[];

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor: "white",
        height: "100%",
        borderRadius: "5px",
      }}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <AccountTree
          sx={{
            fontSize: "40px",
            marginRight: "5px",
            color: "grey",
            margin: "20px 0 0 15px",
          }}
        />
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "#808080", marginRight: "10px", marginTop: "10px" }}
        >
          {devices.length} {strings.devices}
        </Typography>
      </Box>
    </Box>
  );
}
