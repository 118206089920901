import { useState } from "react";

import {
  KeyboardArrowDownOutlined,
  KeyboardArrowUpOutlined,
} from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";

import SwitchGroupGatesConfig from "./components/gates-config/switch-group-gates-config";
import SwitchGroupControl from "./components/gates-control/switch-group-control";

export default function SwitchGroupConfig() {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <SwitchGroupControl />

      {showMore && (
        <SwitchGroupGatesConfig onClose={() => setShowMore(false)} />
      )}

      <Box sx={{ textAlign: "center" }}>
        <IconButton onClick={() => setShowMore(!showMore)}>
          {showMore ? (
            <KeyboardArrowUpOutlined />
          ) : (
            <KeyboardArrowDownOutlined />
          )}
        </IconButton>
      </Box>
    </>
  );
}
