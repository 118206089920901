import LogoutIcon from "@mui/icons-material/Logout";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import client from "../../../api/api";
import { useTranslatedStrings } from "../../../hooks/translate";
import { removeCookie } from "../../../services/cookies";
import { setAuthToken, setUser } from "../../../slices/app-slice";
import AppDrawerItem from "./app-drawer-item";

export default function LogoutButton(props: { open: boolean }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const strings = useTranslatedStrings();

  const handleSignOut = () => {
    client.logoutPost({ logoutEverywhere: false });
    dispatch(setUser(null));
    dispatch(setAuthToken(null));
    removeCookie("authToken");
    navigate("/");
  };
  return (
    <AppDrawerItem
      label={strings.signout}
      open={props.open}
      onClick={handleSignOut}
    >
      <LogoutIcon />
    </AppDrawerItem>
  );
}
