import { useEffect, useState } from "react";

import { Box } from "@mui/material";

import client from "../../../../../../../../../../api/api";
import AnalogStatusChart from "./analog-status-chart";
import ChartControls from "./components/chart-controls/chart-controls";

interface Props {
  deviceId: string;
  topic: string;
  units: string;
  color: string;
  heigth?: number;
}
export default function TopicAnalogStatusChart(props: Props) {
  const [data, setData] = useState<(number | undefined)[]>([]);
  const [timestamps, setTimestamps] = useState<number[]>([]);
  const [date, setDate] = useState(new Date());
  const startOfTheDay = new Date(date);
  startOfTheDay.setHours(0);
  startOfTheDay.setMinutes(0);
  startOfTheDay.setSeconds(0);
  const endOfTheDay = new Date(date);
  endOfTheDay.setHours(23);
  endOfTheDay.setMinutes(59);
  endOfTheDay.setSeconds(59);

  const getData = async () => {
    setData([]);
    const result = await client.apiV4DeviceDataGetListDeviceIdPut(
      props.deviceId,
      {
        topic: props.topic,
        from: startOfTheDay.getTime(),
        to: endOfTheDay.getTime(),
      },
    );
    if (result.status === 200) {
      const startPoint = result.data.initialValue;
      const iValue = startPoint != null ? startPoint.v : undefined;
      processData(result.data.data ?? [], iValue);
    }
  };

  const processData = (
    receivedData: DeviceDataItem[],
    initialValue: number | undefined,
  ) => {
    const newData = receivedData.map((i) => i.v);
    const newTimestamps = receivedData.map((i) => i.t ?? 0);

    if (newData[0]) {
      newTimestamps.unshift(startOfTheDay.getTime());
      newData.unshift(newData[0]);
    } else if (initialValue) {
      newTimestamps.unshift(startOfTheDay.getTime());
      newData.unshift(initialValue);
    }

    let finalPoint = endOfTheDay.getTime();
    const now = Date.now();
    if (now < endOfTheDay.getTime()) {
      finalPoint = now;
    }

    const lastDataValue = newData[newData.length - 1];
    if (lastDataValue) {
      newTimestamps.push(finalPoint);
      newData.push(lastDataValue);
    }

    setData(newData);
    setTimestamps(newTimestamps);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  return (
    <Box
      sx={{ border: "solid 1px #70707037", borderRadius: "5px", margin: "2px" }}
    >
      <Box sx={{ height: props.heigth }}>
        <AnalogStatusChart
          units={props.units}
          color={props.color}
          data={data}
          timestamps={timestamps}
        />
      </Box>
      <ChartControls
        date={date}
        onDateChanged={(newDate) => setDate(newDate)}
      />
    </Box>
  );
}
