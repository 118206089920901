import { useEffect, useState } from "react";

import Lottie from "lottie-react";
import { Link, useParams } from "react-router-dom";

import client from "../../api/api";
import successAnimation from "../../assets/animations/email-confirmed.json";
import errorAnimation from "../../assets/animations/error.json";
import { useTranslatedStrings } from "../../hooks/translate";

export default function ConfirmEmailPage() {
  const strings = useTranslatedStrings();
  const params = useParams();
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const checkConfirmationId = async (id: string) => {
    const response = await client.confirmEmailConfirmationIdPut(id);
    if (response.status == 200) {
      setSuccess(true);
    } else {
      setError(true);
    }
  };

  useEffect(() => {
    const id = params.id;
    checkConfirmationId(id ?? "");
  }, [params]);

  return (
    <div className="smartnest-page">
      <a className="logo-bar" href={process.env.REACT_APP_COMPANY_WEBSITE_URL}>
        <img src="/logo.svg" height="70px" alt="logo" />
        <div>{process.env.REACT_APP_COMPANY_NAME}</div>
      </a>
      <div className="login-dialog animated pulse">
        {success && <h3>{strings.emailConfirmed}</h3>}
        {error && <h3>{strings.linkIsNotValid}</h3>}
        <div className="my-3">
          {success && <Lottie animationData={successAnimation} loop={false} />}
          {error && <Lottie animationData={errorAnimation} loop={false} />}
        </div>
        <Link to="/">
          <button className="btn smartnest-button" type="button">
            {strings.home}
          </button>
        </Link>
      </div>
    </div>
  );
}
