import { Box, Typography } from "@mui/material";
import offIcon from "src/assets/images/device-type-icons/switch-off.svg";
import onIcon from "src/assets/images/device-type-icons/switch.svg";

interface Props {
  isOn: boolean;
  name: string;
  index: number;
  onClick: () => void;
}
export default function SwitchGroupButton(props: Props) {
  const { isOn, onClick, name, index } = props;
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";

  return (
    <Box
      sx={{
        padding: "10px",
        backgroundColor,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        maxHeight: "120px",
        overflow: "hidden",
        cursor: "pointer",
        margin: "5px",
      }}
      onClick={onClick}
    >
      <img src={isOn ? onIcon : offIcon} width={"60"} />
      <Typography variant="h6" sx={{ textAlign: "center", color: "grey" }}>
        {index + 1}: {name}
      </Typography>
    </Box>
  );
}
