import TopicAnalogStatusChart from "./base-charts/topic-analog-status-chart";

interface Props {
  deviceId: string;
}
export default function BatteryChart(props: Props) {
  return (
    <TopicAnalogStatusChart
      deviceId={props.deviceId}
      topic={"battery"}
      color="#317128"
      units="%"
    />
  );
}
