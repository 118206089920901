import { useState } from "react";

import { GoogleReCaptcha } from "react-google-recaptcha-v3";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import client from "../../../api/api";
import FormInputField from "../../../components/form-input-field";
import LoadingIndicator from "../../../components/loading-indicator";
import { useAppSelector } from "../../../hooks/redux";
import useTranslate, { useTranslatedStrings } from "../../../hooks/translate";
import { setCookie } from "../../../services/cookies";
import { setAuthToken } from "../../../slices/app-slice";
import {
  validateEmail,
  validatePassword,
  validateUsername,
} from "../../../utils/field-validation";
import SuccessDialog from "./success-dialog";
import "./signup-form.css";

interface Props {
  onLoginClicked: () => void;
}

export default function SignupForm(props: Props) {
  const strings = useTranslatedStrings();
  const translate = useTranslate();
  const language = useAppSelector((state) => state.app.language);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isInitialState, setIsInitialState] = useState<boolean>(true);
  const [username, setUsername] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [password2, setPassword2] = useState<string | null>(null);
  const [recaptcha, setRecaptcha] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [loading, setloading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

  function isFormValid() {
    return (
      username &&
      validateUsername(username) &&
      password &&
      validatePassword(password) &&
      password === password2 &&
      email &&
      validateEmail(email)
    );
  }

  async function handleSubmitClicked() {
    if (!isFormValid()) {
      setIsInitialState(false);
      return;
    }

    if (username && password && email && name && recaptcha) {
      setloading(true);

      const response = await client.signupPost({
        username: username ?? "",
        password: password ?? "",
        recaptcha: recaptcha ?? "",
        email: email ?? "",
        name: name ?? "",
        language: language,
        source: "Wep App",
      });

      setloading(false);

      if (response.status === 200) {
        const { authToken } = response.data;
        setSuccess(true);
        setCookie("authToken", authToken ?? "");
        client.setToken(authToken);
        dispatch(setAuthToken(authToken));
      } else if (response.response.status === 503) {
        navigate("/service_unavailable");
      } else {
        const { data } = response.response;
        setError(data.errorDescriptionKey);
      }
    }

    setRefreshReCaptcha((r) => !r);
  }

  if (success) return <SuccessDialog message={strings.welcome} />;

  if (loading) return <LoadingIndicator />;

  return (
    <>
      <div className="login-dialog-title">{strings.signup}</div>

      {error && <h6 className="error-message">{translate(error)}</h6>}

      <FormInputField
        isValid={isInitialState ? null : validateUsername(username)}
        type="username"
        placeholder={strings.username}
        errorMessage={strings.usernamereqs}
        onChange={(value) => {
          // props.onFormUpdate();
          setIsInitialState(false);
          setUsername(value);
        }}
      />

      <FormInputField
        isValid={isInitialState ? null : validatePassword(password)}
        type="password"
        placeholder={strings.password}
        errorMessage={strings.passwordhelp}
        onChange={(value) => {
          // props.onFormUpdate();
          setIsInitialState(false);
          setPassword(value);
        }}
      />

      <FormInputField
        isValid={
          isInitialState ? null : password2 === password && password2 != null
        }
        type="password"
        placeholder={strings.confirmpassword}
        errorMessage={strings.passwordsdontmatch}
        onChange={(value) => setPassword2(value)}
      />
      <FormInputField
        isValid={isInitialState ? null : validateEmail(email)}
        type="email"
        placeholder={strings.email}
        errorMessage={strings.emailwrongformat}
        onChange={(value) => setEmail(value)}
      />
      <FormInputField
        isValid={isInitialState ? null : name != null && name != ""}
        type="name"
        placeholder={strings.name}
        errorMessage={strings.name}
        onChange={(value) => setName(value)}
      />

      <div className="spacer"></div>

      <GoogleReCaptcha
        onVerify={setRecaptcha}
        refreshReCaptcha={refreshReCaptcha}
      />

      <button
        className="btn smartnest-button"
        type="button"
        onClick={handleSubmitClicked}
      >
        {strings.signup}
      </button>

      <div className="login-dialog-footer" onClick={props.onLoginClicked}>
        <div>{strings.alreadyRegistered}</div>
        <div className="link">{strings.login}</div>
      </div>
    </>
  );
}
