import { Typography } from "@mui/material";
import { Box } from "@mui/system";

import signal0 from "../../../../../../../../../assets/images/device-info-icons/signal0.svg";
import signal1 from "../../../../../../../../../assets/images/device-info-icons/signal1.svg";
import signal3 from "../../../../../../../../../assets/images/device-info-icons/signal3.svg";
import signal4 from "../../../../../../../../../assets/images/device-info-icons/signal4.svg";

interface Props {
  signal: string | number;
}

export default function DeviceInfoSignalStatus(props: Props) {
  const signal = parseInt(`${props.signal}`, 10);
  let signalIcon = signal0;
  if (isNaN(signal) || signal < -80 || (signal >= 0 && signal < 20)) {
    signalIcon = signal0;
  } else if (signal < -70 || (signal > 0 && signal < 50)) {
    signalIcon = signal1;
  } else if (signal < -60 || (signal > 0 && signal < 75)) {
    signalIcon = signal3;
  } else if (signal >= -60 || (signal > 0 && signal >= 75)) {
    signalIcon = signal4;
  }
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      {signal < 0 && (
        <img src={signalIcon} alt="signal" width="20" height="20" />
      )}
      <Box width={10} />

      <Typography variant="h5">
        {signal} {signal < 0 ? "db" : ""}
      </Typography>
    </Box>
  );
}
