import { useCallback, useContext } from "react";

import { Card, Typography, Switch } from "@mui/material";
import { Box } from "@mui/system";

import client from "../../../../../../../../../../../api/api";
import { useTranslatedStrings } from "../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../device-context";

export default function ScaleSelection() {
  const deviceContext = useContext(DeviceContext);
  const device = deviceContext?.device ?? {};
  const strings = useTranslatedStrings();
  const { config } = device;
  const scale = config.scale ?? "C";

  const onScaleChanged = useCallback(async (isCelsius: boolean) => {
    const scaleString = isCelsius ? "C" : "F";
    await client.apiV4ConfigDeviceDeviceIdPut(device._id ?? "", {
      config: { scale: scaleString },
    });
    deviceContext?.refreshDevice();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography variant="body1">{strings.scale}</Typography>
        <Box sx={{ flex: 2 }} />
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{ color: scale === "C" ? "grey" : "auto" }}
          >
            °F
          </Typography>
          <Switch
            checked={scale == "C"}
            onChange={(e) => onScaleChanged(e.target.checked)}
            inputProps={{ "aria-label": "" }}
          />

          <Typography
            variant="body1"
            sx={{ color: scale === "F" ? "grey" : "auto" }}
          >
            °C
          </Typography>
        </Box>
      </Box>
    </Card>
  );
}
