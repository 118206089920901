import { Box, Typography } from "@mui/material";
import openIcon from "src/assets/images/device-type-icons/contact-sensor-open.svg";
import closedIcon from "src/assets/images/device-type-icons/contact-sensor.svg";

import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function ContactSensorDevice(props: Props) {
  const { device } = props;
  const isOpen = !device.detectionState == true;

  const backgroundColor = !isOpen ? "rgb(51, 51, 51)" : "white";
  const strings = useTranslatedStrings();

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <DeviceWidgetTitle device={device} />

      <Box sx={{ display: "flex", justifyContent: "space-between", flex: 2 }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <img src={isOpen ? openIcon : closedIcon} width={"60"} />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "15px", marginTop: "10px" }}
        >
          {!isOpen ? strings.closed : strings.open}
        </Typography>
      </Box>
    </Box>
  );
}
