import { Chart, registerables } from "chart.js";
import { Line } from "react-chartjs-2";
import "chartjs-adapter-moment";

import { useAppSelector } from "../../../../../../../../../../hooks/redux";
import { addAlpha } from "../../../../../../../../../../utils/color-utils";

Chart.register(...registerables);

interface Props {
  data: (number | undefined)[];
  timestamps: number[];
  color: string;
  valueWhenTrue: string;
  valueWhenFalse: string;
}
export default function BinaryStatusChart(props: Props) {
  const { timestamps, data } = props;
  const mainColor = props.color;
  const lightColor = addAlpha(props.color, 0.3);
  const strongColor = addAlpha(props.color, 0.8);

  const theme = useAppSelector((state) => state.app.theme);
  const gridXColor = theme === "dark" ? "#575757" : "#FBFBFB";
  const gridYColor = addAlpha(gridXColor, 0.6);

  return (
    <Line
      data={{
        labels: timestamps,
        datasets: [
          {
            label: "t",
            data: data,
            stepped: true,
            fill: "start",
          },
        ],
      }}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        elements: {
          point: { radius: 2, hoverRadius: 10, backgroundColor: strongColor },
          line: {
            fill: false,
            backgroundColor: lightColor,
            borderColor: mainColor,
          },
        },
        plugins: {
          legend: { display: false },
          tooltip: {
            callbacks: {
              label: (item) =>
                item.formattedValue == "1"
                  ? props.valueWhenTrue
                  : props.valueWhenFalse,
            },
          },
        },
        scales: {
          x: {
            type: "time",
            border: { display: false },
            ticks: {
              display: true,
              color: "grey",
              maxTicksLimit: 5,
              font: { size: 10, weight: "bold" },
            },
            grid: {
              color: gridXColor,
            },
          },
          y: {
            max: 1.1,
            min: 0,
            border: { display: false },
            ticks: {
              display: false,
              color: "#C1C1C1",
              maxTicksLimit: 2,
              font: { size: 8 },
            },
            grid: {
              color: gridYColor,
            },
          },
        },
      }}
    />
  );
}
