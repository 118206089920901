import { useCallback } from "react";

import { MonochromePhotos, Image, Print } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useQueryClient } from "react-query";

import client from "../../../../../../../../../api/api";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function PhotoBoothDevice(props: Props) {
  const queryClient = useQueryClient();

  const isOn = props.device.powerState == "ON";
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";

  const onClick = useCallback(async () => {
    const deviceId = props.device._id ?? "";
    const response = await client.apiV4DirectiveDeviceDeviceIdPut(deviceId, {
      source: "web",
      directive: { field: "powerState", value: isOn ? "OFF" : "ON" },
    });
    if (response.status == 200) {
      queryClient.invalidateQueries(["devices", `${deviceId}`]);
    }
  }, [props.device._id, queryClient, isOn]);

  return (
    <Box
      sx={{
        padding: "3px",
        backgroundColor,
        height: "100%",
        borderRadius: "5px",
      }}
      onClick={onClick}
    >
      <DeviceWidgetTitle device={props.device} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <MonochromePhotos
          sx={{
            fontSize: "40px",
            marginRight: "5px",
            color: "grey",
            margin: "20px 0 0 15px",
          }}
        />
        <Box sx={{ flex: 1 }} />
        <Box sx={{ marginRight: "10px", marginTop: "10px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Image sx={{ color: "grey" }} />
            <Box sx={{ width: 10 }} />
            <Typography
              variant="h4"
              sx={{ color: "#808080", marginRight: "10px", width: "20px" }}
            >
              {props.device.units ?? 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            <Print sx={{ color: "grey" }} />
            <Box sx={{ width: 10 }} />
            <Typography
              variant="h4"
              sx={{ color: "#808080", marginRight: "10px", width: "20px" }}
            >
              {props.device.value ?? 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
