import alexa from "../assets/images/integrations/alexa.png";
import app from "../assets/images/integrations/app.png";
import espurna from "../assets/images/integrations/espurna.png";
import google from "../assets/images/integrations/google.png";
import homeAssistant from "../assets/images/integrations/homeAssistant.png";
import homeBridge from "../assets/images/integrations/homeBridge.png";
import ifttt from "../assets/images/integrations/ifttt.png";
import siri from "../assets/images/integrations/siri.png";
import smartthings from "../assets/images/integrations/smartthings.png";
import tasmota from "../assets/images/integrations/tasmota.png";
import zigbee2mqtt from "../assets/images/integrations/zigbee2mqtt.png";

export const integrationTypes = [
  "alexa",
  "google",
  "siri",
  "ifttt",
  "app",
  "smartthings",
  "espurna",
  "tasmota",
  "homeAssistant",
  "homeBridge",
  "zigbee2mqtt",
];

export const getIntegrationLogo = (name: string) => {
  switch (name) {
    case "alexa":
      return alexa;
    case "google":
      return google;
    case "siri":
      return siri;
    case "ifttt":
      return ifttt;
    case "app":
      return app;
    case "smartthings":
      return smartthings;
    case "espurna":
      return espurna;
    case "tasmota":
      return tasmota;
    case "homeAssistant":
      return homeAssistant;
    case "homeBridge":
      return homeBridge;
    case "zigbee2mqtt":
      return zigbee2mqtt;
    default:
      return alexa;
  }
};

export const getIntegrationDocuUrl = (name: string, language: string) => {
  const baseUrlEs = "https://www.documentacion.smartnest.cz";
  const baseUrlEn = "https://www.docu.smartnest.cz";

  switch (name) {
    case "alexa":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-alexa`
        : `${baseUrlEn}/alexa-integration`;
    case "google":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-google`
        : `${baseUrlEn}/google-home-integration`;
    case "siri":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-siri`
        : `${baseUrlEn}/siri-integration`;
    case "ifttt":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-ifttt`
        : `${baseUrlEn}/ifttt-integration`;
    case "app":
      return language == "ES"
        ? `${baseUrlEs}/uso-de-la-applicacion-web/descarga-la-aplicacion-movil`
        : `${baseUrlEn}/using-web-app/download-the-mobile-app`;
    case "smartthings":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-smartthings`
        : `${baseUrlEn}/smartthings-integration`;
    case "espurna":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-espurna`
        : `${baseUrlEn}/espurna-integration`;
    case "tasmota":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-tasmota`
        : `${baseUrlEn}/tasmota-integration`;
    case "homeAssistant":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-home-assistant`
        : `${baseUrlEn}/home-assistant-integration`;
    case "homeBridge":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-homebridge`
        : `${baseUrlEn}/homebridge-integration`;
    case "zigbee2mqtt":
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-zigbee2mqtt`
        : `${baseUrlEn}/zigbee2mqtt-integration`;
    default:
      return language == "ES"
        ? `${baseUrlEs}/integracion-con-alexa`
        : `${baseUrlEn}/alexa-integration`;
  }
};
