import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import { Box, Fab } from "@mui/material";
import Grid from "@mui/material/Grid";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { setAddingItem } from "../../../../slices/devices-slice";
import DeviceWidget from "./components/device-widget/device-widget";

export default function GroupDevices() {
  const devices = useAppSelector((state) => state.devicesPage.devices);
  const dispatch = useAppDispatch();
  const handleAddClicked = () => {
    dispatch(setAddingItem(true));
  };

  return (
    <Box
      sx={{
        position: "relative",
        width: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Grid sx={{ flexGrow: 1, overflow: "auto" }} container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" spacing={2}>
            {devices.map((device) => (
              <DeviceWidget key={device._id} deviceId={device._id ?? ""} />
            ))}
          </Grid>
        </Grid>
      </Grid>
      <Fab
        onClick={handleAddClicked}
        color="primary"
        aria-label="add"
        sx={{ position: "absolute", bottom: 20, right: 20 }}
      >
        <AddIcon />
      </Fab>
    </Box>
  );
}
