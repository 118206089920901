import { useState } from "react";

import CircularSlider from "@fseehawer/react-circular-slider";
import { Box, Typography } from "@mui/material";
import offIcon from "src/assets/images/device-type-icons/light-off.svg";
import onIcon from "src/assets/images/device-type-icons/light.svg";

interface Props {
  isOn: boolean;
  percentage: number;
  name: string;
  index: number;
  onClick: () => void;
  onChange: (percentage: number) => void;
}
export default function LighthGroupButton(props: Props) {
  const { isOn, percentage, onClick, onChange, name, index } = props;
  const backgroundColor = isOn ? "white" : "rgb(51, 51, 51)";
  const textColor = isOn ? "rgb(51, 51, 51)" : "white";

  const [value, setValue] = useState(percentage);

  const step = 1;
  const numbers = Array.from(Array(100).keys());
  const data = numbers.map((val) => `${(val * step).toFixed(1)}`);

  const onIsDraggingChange = (val: boolean) => {
    if (!val && value) {
      onChange(value);
    }
  };
  const onsSetpointChange = (val: string) => {
    setValue(Number(val));
  };

  return (
    <Box
      sx={{
        padding: "10px",
        backgroundColor,
        borderRadius: "5px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        width: "100px",
        maxHeight: "140px",
        overflow: "hidden",
        cursor: "pointer",
        margin: "5px",
      }}
    >
      <Typography variant="h6" sx={{ textAlign: "center", color: "grey" }}>
        {index + 1}: {name}
      </Typography>

      <Box
        sx={{
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularSlider
          width={90}
          renderLabelValue={<div />}
          valueFontSize="2rem"
          data={data}
          isDragging={onIsDraggingChange}
          dataIndex={percentage}
          knobColor="#ee8003"
          knobPosition="bottom"
          progressColorFrom={"#E1B27B"}
          progressColorTo="#EA983B"
          progressSize={10}
          trackColor={isOn ? "#eeeeee" : "#858585"}
          trackSize={10}
          onChange={onsSetpointChange}
        />
        <img
          src={isOn ? onIcon : offIcon}
          width={"40"}
          style={{ position: "absolute", zIndex: 3 }}
          onClick={onClick}
        />
      </Box>
      <Typography
        variant="h6"
        sx={{ textAlign: "center", color: textColor, marginTop: "10px" }}
      >
        {percentage} %
      </Typography>
    </Box>
  );
}
