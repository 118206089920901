import * as React from "react";

import CloudIcon from "@mui/icons-material/CloudOffRounded";
import { Box } from "@mui/material";
import Lottie from "lottie-react";

import animation from "../../../../../../../assets/animations/online.json";

interface Props {
  device: Device;
}
export default function DeviceWidgetTitle(props: Props) {
  const { device } = props;

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        marginLeft: "5px",
      }}
    >
      {device.connected ? (
        <Lottie
          animationData={animation}
          loop
          style={{ height: 20, width: 20, marginRight: "5px" }}
        />
      ) : (
        <CloudIcon
          style={{ height: 15, width: 15, marginRight: "5px" }}
          htmlColor="grey"
        />
      )}
      <Box
        sx={{
          maxHeight: "20px",
          overflow: "hidden",
          fontSize: "15px",
          color: "grey",
        }}
      >
        {device.name}
      </Box>
    </Box>
  );
}
