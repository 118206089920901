import { Box, Button, DialogContent, DialogTitle } from "@mui/material";
import deviceIcon from "src/assets/images/icons/device.svg";
import groupIcon from "src/assets/images/icons/group.svg";

import { useTranslatedStrings } from "../../../../../hooks/translate";

interface Props {
  onSelect: (type: string) => void;
}
export default function SelectAddItemType(props: Props) {
  const strings = useTranslatedStrings();

  return (
    <Box>
      <DialogTitle variant="h2">{strings.addItem}</DialogTitle>
      <DialogContent dividers>
        <Box sx={{ display: "flex" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.onSelect("group")}
          >
            <Box>
              <img src={groupIcon} alt="group" />
              {strings.group}
            </Box>
          </Button>
          <Box width={20} />
          <Button
            variant="contained"
            color="secondary"
            onClick={() => props.onSelect("device")}
          >
            <Box>
              <img src={deviceIcon} alt="device" />
              {strings.device}
            </Box>
          </Button>
        </Box>
      </DialogContent>
    </Box>
  );
}
