import { useTranslatedStrings } from "../../../../../../../../../hooks/translate";
import TopicBinaryStatusChart from "./base-charts/topic-binary-status-chart";

interface Props {
  deviceId: string;
  n?: string;
}
export default function PowerStateChart(props: Props) {
  const strings = useTranslatedStrings();
  const n = props.n ?? "";
  return (
    <TopicBinaryStatusChart
      deviceId={props.deviceId ?? ""}
      topic={`powerState${n}`}
      color="#D2DD11"
      valueWhenFalse={strings.off}
      valueWhenTrue={strings.on}
      heigth={70}
    />
  );
}
