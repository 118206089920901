import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import { useAppSelector } from "../../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../../hooks/translate";

interface Props {
  value?: string;
  onSelect: (groupId?: string) => void;
}
export default function DeviceGroupSelect(props: Props) {
  const groups = useAppSelector((state) => state.devicesPage.groups);
  const strings = useTranslatedStrings();
  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target;
    if (value === "no-group") props.onSelect();
    else props.onSelect(value);
  };

  return (
    <div>
      <FormControl sx={{ m: 1, minWidth: 200 }}>
        <InputLabel>{strings.group}</InputLabel>
        <Select
          value={props.value ?? "no-group"}
          label={strings.group}
          onChange={handleChange}
        >
          <MenuItem key={"no-group"} value={"no-group"}>
            {strings.nogroup}
          </MenuItem>
          {groups.map((group: Group) => (
            <MenuItem key={group._id} value={group._id}>
              {group.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
