import { useContext } from "react";

import { Card, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { useTranslatedStrings } from "../../../../../../../../../../../../hooks/translate";
import { DeviceContext } from "../../../../../../../device-context";
import ZigbeeDeviceItem from "./components/zigbee-device";

export default function ZigbeeDevicesManagement() {
  const deviceContext = useContext(DeviceContext);
  const device = deviceContext?.device ?? {};
  const strings = useTranslatedStrings();

  const { integrations } = device;
  const filtered = integrations?.filter((i) => i.name == "zigbee2mqtt");
  if (!filtered || filtered.length == 0) return null;

  const { config } = filtered[0];
  const devices = config["zigbeeDevices"] as ZigbeeDevice[];
  const deviceIds = config["zigbeeChildrenIds"];

  return (
    <Card sx={{ margin: "10px 0", padding: "5px 10px" }}>
      <Box sx={{}}>
        <Typography variant="body1">
          {devices.length} {strings.devices}
        </Typography>
        <Box sx={{ flex: 2 }} />
        <Box sx={{}}>
          {devices.map((item) => (
            // <h3 key={item.address}>{item.address}</h3>
            <ZigbeeDeviceItem
              key={item.address}
              device={item}
              linkedId={deviceIds[item.address ?? ""]}
              parentId={device._id ?? ""}
            />
          ))}
        </Box>
      </Box>
    </Card>
  );
}
