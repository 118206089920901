import { useCallback, useEffect } from "react";

import { Box } from "@mui/material";

import client from "../../api/api";
import MiniDrawer from "../../components/app-drawer/app-drawer";
import { useAppDispatch, useAppSelector } from "../../hooks/redux";
import useWindowDimensions from "../../hooks/window-dimensions";
import WebSocketDemo from "../../services/ws";
import { setDevices, setGroups } from "../../slices/devices-slice";
import AddItemDialog from "./components/add-item/add-item";
import DeviceDetailsDialog from "./components/device-details/device-details";
import GroupDetailsDialog from "./components/group-details/group-details";
import GroupDevices from "./components/group-devices/group-devices";
import GroupList from "./components/group-list/group-list";

export default function DevicesPage() {
  const dispatch = useAppDispatch();
  const addingItem = useAppSelector((state) => state.devicesPage.addingItem);
  const user = useAppSelector((state) => state.app.user);
  const { width, height } = useWindowDimensions();

  const filteredByGroupId = useAppSelector(
    (state) => state.devicesPage.filteredByGroupId,
  );
  const selectedGroupId = useAppSelector(
    (state) => state.devicesPage.selectedGroupId,
  );
  const selectedDeviceId = useAppSelector(
    (state) => state.devicesPage.selectedDeviceId,
  );

  const getDevices = useCallback(async () => {
    if (!user) return;
    const groupId = filteredByGroupId ?? "";

    const response = await client.apiV4DevicesGet(groupId);
    if (response.status == 200) {
      dispatch(setDevices(response.data.devices));
    }
  }, [dispatch, filteredByGroupId, user]);

  const getGroups = useCallback(async () => {
    if (!user) return;

    const response = await client.apiV4GroupsGet();
    if (response.status == 200) {
      const groups = response.data.groups;
      dispatch(setGroups(groups));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredByGroupId, user, dispatch]);

  useEffect(() => {
    getDevices();
  }, [getDevices, filteredByGroupId, selectedDeviceId, addingItem, user]);

  useEffect(() => {
    if (!addingItem) getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addingItem, user]);

  useEffect(() => {
    if (!selectedGroupId) getGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId, user]);

  return (
    <WebSocketDemo>
      <MiniDrawer title="Devices">
        <>
          {/* {width < 800 && <GroupList />} //TODO:show group selection when on mobile */}
          <Box
            sx={{
              display: "flex",
              height: `${height - 130}px`,
            }}
          >
            {width > 450 && <GroupList />}
            <GroupDevices />
          </Box>

          <AddItemDialog />
          <DeviceDetailsDialog deviceId={selectedDeviceId} />
          <GroupDetailsDialog groupId={selectedGroupId} />
        </>
      </MiniDrawer>
    </WebSocketDemo>
  );
}
