import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

import LoadingIndicator from "../../components/loading-indicator";
import { useAppSelector } from "../../hooks/redux";
import { RootState } from "../../store";

export default function SplashPage() {
  const authToken = useAppSelector((state: RootState) => state.app.authToken);
  const navigate = useNavigate();

  useEffect(() => {
    if (authToken) {
      setTimeout(() => navigate("/devices"), 1500);
    } else {
      navigate("/login");
    }
  }, [authToken, navigate]);

  return (
    <div className="">
      <LoadingIndicator />
    </div>
  );
}
