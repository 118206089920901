/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

export const routines = createSlice({
  name: "routines",
  initialState: {
    routines: [],
    devices: [],
    selectedRoutineId: null,
    addingRoutine: false,
    editingRoutine: false,
    loading: false,
  },
  reducers: {
    loadingStart: (state) => {
      state.loading = true;
    },
    loadingEnd: (state) => {
      state.loading = false;
    },
    routineSelect: (state, action) => {
      state.selectedRoutineId = action.payload;
      state.editingRoutine = true;
    },
    addRoutine: (state) => {
      state.addingRoutine = true;
      state.editingRoutine = false;
    },
    editRoutine: (state) => {
      state.addingRoutine = false;
      state.editingRoutine = true;
    },
    finishAddEdit: (state) => {
      state.addingRoutine = false;
      state.editingRoutine = false;
      state.selectedRoutineId = null;
    },
    updateData: (state, action) => {
      state.routines = action.payload.routines;
      state.devices = action.payload.devices;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  loadingStart,
  loadingEnd,
  routineSelect,
  addRoutine,
  editRoutine,
  updateData,
  finishAddEdit,
} = routines.actions;

export default routines.reducer;
