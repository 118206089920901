import * as React from "react";

import List from "@mui/material/List";
import ListSubheader from "@mui/material/ListSubheader";

import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { useTranslatedStrings } from "../../../../hooks/translate";
import { setFilteredByGroupId } from "../../../../slices/devices-slice";
import GroupListItem from "./components/group-list-item";

export default function GroupList() {
  const strings = useTranslatedStrings();
  const dispatch = useAppDispatch();
  const groups = useAppSelector((state) => state.devicesPage.groups);
  const handleGroupClicked = (groupId: string) => {
    dispatch(setFilteredByGroupId(groupId));
  };

  const otherDevicesGroup: Group = {
    name: strings.nogroup,
    iconName: "all-icon",
  };
  const allGroups = [...groups];
  allGroups.push(otherDevicesGroup);

  const style = {
    width: "100%",
    maxWidth: 300,
    bgcolor: "background.paper",
    marginRight: "20px",
    overflow: "auto",
  };

  return (
    <List
      sx={style}
      component="nav"
      aria-labelledby="group-list-subheader"
      subheader={
        <ListSubheader component="div" id="group-list-subheader">
          {strings.groups}
        </ListSubheader>
      }
    >
      {allGroups.map((group) => (
        <GroupListItem
          group={group}
          key={group._id ?? "-"}
          onClick={() => handleGroupClicked(group._id ?? "")}
        />
      ))}
    </List>
  );
}
