import TopicDailyChart from "./base-charts/topic-daily-chart";

interface Props {
  deviceId: string;
}
export default function HumidityChart(props: Props) {
  return (
    <TopicDailyChart
      deviceId={props.deviceId}
      topic={"humidity"}
      color="#4F62F5"
      units="%"
    />
  );
}
