import EN from "./en.json";
// import CZ from "./cz.json";
// import DE from "./de.json";
// import ES from "./es.json";
// import FR from "./fr.json";
// import IT from "./it.json";
// import JP from "./jp.json";
// import PT from "./pt.json";

export const translate = (expresion: string, lang: string) => {
  let language = lang;
  if (
    lang !== "EN" &&
    lang !== "ES" &&
    lang !== "DE" &&
    lang !== "CZ" &&
    lang !== "FR" &&
    lang !== "PT" &&
    lang !== "IT" &&
    lang !== "JP"
  ) {
    language = "EN";
  }

  let libr: typeof EN;
  switch (language) {
    // case "EN":
    //   libr = EN;
    //   break;
    // case "ES":
    //   libr = ES;
    //   break;
    // case "FR":
    //   libr = FR;
    //   break;
    // case "DE":
    //   libr = DE;
    //   break;
    // case "CZ":
    //   libr = CZ;
    //   break;
    // case "PT":
    //   libr = PT;
    //   break;
    // case "IT":
    //   libr = IT;
    //   break;
    // case "JP":
    //   libr = JP;
    //   break;
    default:
      libr = EN;
  }
  const index = Object.keys(libr).indexOf(expresion);
  if (index === -1) {
    console.log("❌ Error translating", expresion, "in", language);
    return "ERROR";
  }

  const value = Object.values(libr).at(index);
  return value ?? "ERROR";
};

export const getStrings = (lang: string) => {
  switch (lang) {
    // case "EN":
    //   return EN;
    // case "ES":
    //   return ES;
    // case "FR":
    //   return FR;
    //   break;
    // case "DE":
    //   return DE;
    //   break;
    // case "CZ":
    //   return CZ;
    //   break;
    // case "PT":
    //   return PT;
    //   break;
    // case "IT":
    //   return IT;
    //   break;
    // case "JP":
    //   return JP;
    //   break;
    default:
      return EN;
  }
};
