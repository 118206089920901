import { useCallback, useEffect, useState } from "react";

import { Box, Typography } from "@mui/material";
import icon from "src/assets/images/device-type-icons/analog-sensor.svg";

import api from "../../../../../../../../../api/api";
import { defaultUpdateInterval } from "../../../../../../../../../utils/device-utils";
import DeviceWidgetTitle from "../../device-title";

interface Props {
  device: Device;
}
export default function AnalogSensorDevice(props: Props) {
  const [device, setDevice] = useState<Device>(props.device);
  const { value } = device;
  const deviceId = device._id ?? "";

  const getDevice = useCallback(async () => {
    const response = await api.apiV4DevicesDeviceIdGet(deviceId);
    if (response.status == 200) {
      const newDevice = response.data.device;
      if (newDevice) setDevice(newDevice);
    }
  }, [deviceId]);

  useEffect(() => {
    const timer = setInterval(getDevice, defaultUpdateInterval);
    return () => clearInterval(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formattedValue = value?.toFixed(1) ?? "--.--";

  return (
    <Box
      sx={{
        backgroundColor: "white",
        height: "100%",
        borderRadius: "5px",
      }}
    >
      <DeviceWidgetTitle device={device} />
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box
          sx={{
            paddingLeft: "10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginTop: "10px",
          }}
        >
          <img style={{}} src={icon} width={"60"} />
        </Box>
        <Box sx={{ flex: 1 }} />
        <Typography
          variant="h2"
          sx={{ color: "grey", marginRight: "20px", marginTop: "10px" }}
        >
          {formattedValue}
        </Typography>
      </Box>
    </Box>
  );
}
